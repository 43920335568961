import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";

import Moment from "react-moment";
import moment from "moment-timezone";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Pie,
  Cell,
  AreaChart,
  PieChart as PieChartold,
} from "recharts";
import Modal from "react-bootstrap/Modal";
import { Select, Dropdown, Space } from "antd";
import { PieChart, pieChartDefaultProps } from "react-minimal-pie-chart";
import "./opportunityDashboard.css";
import { Col, Row, Form, Container } from "react-bootstrap";
import { Area } from "@ant-design/plots";
import LeftSideBar from "../LeftSideBar/LeftSideBar";
import axios from "axios";
import config from "../../CommonFiles/config.json";
const mainurl = Object.values(config["URL"]);
const APIBASEURL = Object.values(config["API_BASEPATH"]).join("");
const URL = mainurl.join("");
const mainURL = URL;

function OpportunityDashboard(props) {
  let [popupstatus, setPopupstatus] = useState(false);
  const [selected, setSelected] = useState(0);
  const [genderCount, setGenerCount] = useState([]);
  const [alldata, setAlldata] = useState([]);
  const [insightData, setInsightData] = useState([]);
  const [viewData, setViewdata] = useState([]);
  const [registerState, setRegisterState] = useState([]);
  // const [registerStartDate, setRegisterStartDate] = useState("");
  // const [registerEndDate, setRegisterEndDate] = useState("");
  const [activeToptab, setActiveToptab] = useState("Daily");

  const [collages, setAllcollages] = useState();
  const [matched, setAllmatched] = useState();
  let [filterstatus, setFilterstatus] = useState(0);
  const [organisationtext, setOrganisationtext] = useState();
  const [allRounds, setAllrounds] = useState();
  const registerEndDate = useRef();
  const registerStartDate = useRef();
  const registerviewEndDate = useRef();
  const registerviewStartDate = useRef();
  const params = useParams();
  const [filterData, setFilterData] = useState({
    opportunity_id: params.id,
    school: "",
    gender: "",
    current_round: "",
    year_of_study: "",
  });
  const alluserData = JSON.parse(localStorage.getItem("alldata"));
  const USER_TOKEN = localStorage.getItem("token");
  const AuthStr = "Bearer ".concat(USER_TOKEN);
  const today = new Date();
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  async function exportproducts(params) {
    try {
      const result = await axios({
        method: "get",
        url:
          mainURL + "/registers/excel?opportunity_id=" + props.match.params.id,
        data: {
          opportunity_id: props.match.params.id,
        },
      });
      console.log("product re", result.data.data);
      let excelurl = APIBASEURL + "/" + result.data.path;
      window.open(excelurl, "_blank");
    } catch (error) {
      console.log(error);
    }
  }

  async function exportRegistrations() {
    try {
      const result = await axios({
        method: "get",
        url:
          mainURL + "/v1/registers/excel?opportunity_id=" + props.match.params.id,
        data: {
          opportunity_id: props.match.params.id,
        },
      });
      console.log("product re", result.data.data);
      let excelurl = APIBASEURL + "/" + result.data.path;
      window.open(excelurl, "_blank");
    } catch (error) {
      console.log(error);
    }
  }

  const items = [
    {
      label: (
        <span onClick={() => exportRegistrations()}>
          <i className="fa fa-download" aria-hidden="true"></i> Download
          Registration List
        </span>
      ),
      key: "0",
    }
  ];

  const formattedToday = yyyy + "-" + mm + "-" + dd;
  useEffect(() => {
    if (USER_TOKEN === null) {
      props.history.push(`/login`);
    } else {
      axios({
        method: "POST",
        url: mainURL + "/dashboard",
        data: { opportunity_id: params.id },
        headers: { Authorization: AuthStr },
      })
        .then((response) => {
          const alldatas = response.data;
          setAlldata(alldatas);
          console.log("all data", alldata);
          setGenerCount(alldatas.genderCount);
          getRegistrationState(response.data.opportunity.start_date);
          getViewData(response.data.opportunity.start_date);

          let startdata = (
            <Moment
              format="DD-MM-YY"
              date={alldatas?.opportunity?.start_date}
            />
          );
          let endDate = (
            <Moment format="DD-MM-YY" date={alldatas?.opportunity?.end_date} />
          );
          axios({
            method: "get",
            url:
              mainURL + "/profile/dashboard/rounds/opportunities/" + params.id,
            headers: { Authorization: AuthStr },
          })
            .then((response) => {
              const alldatas = response.data.data;
              setAllrounds(alldatas);
            })
            .catch(function (response) {
              //handle error
              console.log("Error while calling api", response);
            });

          axios({
            method: "get",
            url: mainURL + "/allcollages",
          })
            .then((response) => {
              const alldatas = response.data.data;
              setAllcollages(alldatas);
            })
            .catch(function (response) {
              //handle error
              console.log("Error while calling api", response);
            });
        })

        .catch(function (response) {
          //handle error
          console.log("Error while calling api", response);
        });
      axios({
        method: "POST",
        url: mainURL + "/registration-insights",
        data: { opportunity_id: params.id },
        headers: { Authorization: AuthStr },
      })
        .then((response) => {
          const alldatas = response.data.registrationInsights;
          setInsightData(alldatas);
        })
        .catch(function (response) {
          //handle error
          console.log("Error while calling api", response);
        });
    }
  }, []);

  const getRegistrationState = (oppStartDate) => {
    axios({
      method: "POST",
      url: mainURL + "/registrations",
      data: {
        opportunity_id: params.id,
        start_date: oppStartDate,
        end_date: formattedToday,
      },
      headers: { Authorization: AuthStr },
    })
      .then((response) => {
        const alldatas = response.data.registration;
        setRegisterState(alldatas);
      })
      .catch(function (response) {
        //handle error
        console.log("Error while calling api", response);
      });
  };

  const getViewData = (oppStartDate) => {
    axios({
      method: "POST",
      url: mainURL + "/views",
      data: {
        opportunity_id: params.id,
        start_date: oppStartDate,
        end_date: formattedToday,
      },
      headers: { Authorization: AuthStr },
    })
      .then((response) => {
        const alldatas = response.data.opportunityViews;
        setViewdata(alldatas);
      })
      .catch(function (response) {
        //handle error
        console.log("Error while calling api", response);
      });
  };
  const domain = [
    { name: "All", value: "All" },
    { name: "Engineering", value: "Engineering" },
    { name: "MBA", value: "MBA" },
    { name: "Design", value: "Design" },
    { name: "Fashion", value: "Fashion" },
  ];
  const COLORS = ["#FF0266", "#0BCCFE", "gray"];
  const handleShow = () => {
    setPopupstatus(true);
  };
  const close = () => {
    setPopupstatus(false);
  };

  const selectFilter = (val, name) => {
    setFilterData({ ...filterData, [name]: val });
    console.log("filter response", filterData);
  };
  const handleChange = (value) => {
    console.log(`Selected: ${value}`);
  };

  const submitFilter = () => {
    axios({
      method: "POST",
      url: mainURL + "/profile/dashboard",
      data: filterData,
      headers: { Authorization: AuthStr },
    })
      .then((response) => {
        const alldatas = response.data;
        setAlldata(alldatas);
        setGenerCount(alldatas.genderCount);
      })
      .catch(function (response) {
        //handle error
        console.log("Error while calling api", response);
      });
  };
  const lineWidth = 60;
  const topheader = [
    {
      name: "Daily",
      id: 3,
    },
    {
      name: "Weekly",
      id: 1,
    },
    {
      name: "Annually",
      id: 2,
    },
  ];
  function changeTab(tab) {
    setActiveToptab(tab);
    axios({
      method: "POST",
      url: mainURL + "/views",
      data: {
        opportunity_id: params.id,
        type: tab,
      },
      headers: { Authorization: AuthStr },
    })
      .then((response) => {
        const alldatas = response.data.opportunityViews;
        setViewdata(alldatas);
      })
      .catch(function (response) {
        //handle error
        console.log("Error while calling api", response);
      });
  }

  const dateFilter = (val, name, from) => {
    console.log("date", val, name, registerStartDate.current, registerEndDate);
    if (name === "start_date") {
      registerStartDate.current = val;
    } else {
      registerEndDate.current = val;
    }

    if (
      registerEndDate.current != undefined &&
      registerStartDate.current != undefined
    ) {
      console.log(
        "call api",
        registerEndDate.current,
        registerStartDate.current,
      );
      let finalurl;

      axios({
        method: "POST",
        url: mainURL + "/registrations",
        data: {
          opportunity_id: params.id,
          start_date: registerStartDate.current,
          end_date: registerEndDate.current,
        },
        headers: { Authorization: AuthStr },
      })
        .then((response) => {
          // const alldatas = response.data.regi
          console.log("success while calling api", response);
          const alldatas = response.data.registration;
          setRegisterState(alldatas);
          // strationInsights;
          // setInsightData(alldatas);
        })
        .catch(function (response) {
          //handle error
          console.log("Error while calling api", response);
        });
    }
  };

  // FOR VIEW LIST DATA

  const viewdateFilter = (val, name, from) => {
    console.log(
      "date",
      val,
      name,
      registerviewStartDate.current,
      registerviewEndDate,
    );
    if (name === "start_date") {
      registerviewStartDate.current = val;
    } else {
      registerviewEndDate.current = val;
    }

    if (
      registerviewEndDate.current != undefined &&
      registerviewStartDate.current != undefined
    ) {
      console.log(
        "call api",
        registerviewEndDate.current,
        registerviewStartDate.current,
      );
      axios({
        method: "POST",
        url: mainURL + "/views",
        data: {
          opportunity_id: params.id,
          start_date: registerviewStartDate.current,
          end_date: registerviewEndDate.current,
        },
        headers: { Authorization: AuthStr },
      })
        .then((response) => {
          const alldatas = response.data.opportunityViews;
          setViewdata(alldatas);
        })
        .catch(function (response) {
          //handle error
          console.log("Error while calling api", response);
        });
    }
  };
  const config2 = {
    data: viewData,
    xField: "name",
    yField: "uv",
    xAxis: {
      range: [0, 1],
      tickCount: 5,
    },
    areaStyle: () => {
      return {
        fill: "l(270) 0:#ffffff 0.5:#7ec2f3 1:#1890ff",
      };
    },
  };

  return (
    <div className="wrapper pt-5">
      {/* <UserHeader/> */}
      {/* START LEFTSIDEBAR */}
      <LeftSideBar />
      {/* END LEFTSIDEBAR */}
      {/* START PAGE CONTENT START */}
      <div className="contents opportunityDashboard">
        <div className="page_content pt-5">
          <Container>
            <Row className=" card_box_sec">
              <Col className="col-md-6">
                <h5 className="pb-3 topheading">
                  Good Morning , {alluserData.first_name}{" "}
                  {alluserData.last_name}!
                </h5>
              </Col>
              <Col className="col-md-6 text-md-right">
                <Dropdown
                  menu={{
                    items,
                  }}
                  trigger={["click"]}
                >
                  <a onClick={(e) => e.preventDefault()}>
                    <Space>
                      <span className="dotcss">...</span>

                      {/* <DownOutlined /> */}
                    </Space>
                  </a>
                </Dropdown>
              </Col>
              <Col className="col-md-3">
                <div className="toprow">
                  <div className="imgeshow">
                    <img
                      className="list-icon-img"
                      src={require("../../../images/total_reg.png").default}
                      alt="od-banner"
                    />
                  </div>
                  <div className="maintopbox">
                    Total Completed Registrations
                    <br />
                    <div className="topRowbox">
                      {alldata && alldata.totalRegistration}{" "}
                      <span>
                        (+
                        {moment(new Date())
                          .tz("Asia/Kolkata")
                          .format("YYYY-MM-DD") ===
                        registerState[registerState.length - 1]?.date
                          ? registerState[registerState.length - 1]?.count
                          : "0"}
                        )
                      </span>
                    </div>
                  </div>
                </div>
              </Col>
              <Col className="col-md-3">
                <div className="toprow">
                  <div className="imgeshow">
                    <img
                      className="list-icon-img"
                      src={require("../../../images/view.png").default}
                      alt="od-banner"
                    />
                  </div>
                  <div className="">
                    Views
                    <br />
                    <div className="topRowbox">
                      {alldata?.opportunity?.views}{" "}
                      <span>(+{alldata.todaysViews})</span>
                    </div>
                  </div>
                </div>
              </Col>
              <Col className="mobile_half_width col-md-3">
                <div className="toprow">
                  <div className="imgeshow">
                    <img
                      className="list-icon-img"
                      src={require("../../../images/reg_deadline.png").default}
                      alt="od-banner"
                    />
                  </div>
                  <div className="">
                    Registration Deadline
                    <br />
                    <div className="topRowbox">
                      <Moment
                        format="DD-MM-YY"
                        date={alldata?.opportunity?.register_end_date}
                      />
                      , {alldata?.opportunity?.register_end_time} hrs
                    </div>
                  </div>
                </div>
              </Col>
              <Col className="mobile_half_width col-md-3">
                <div className="toprow">
                  {genderCount.length > 0 ? (
                    <>
                      <div className="imgeshow totalgenermaindiv">
                        <PieChartold width={40} height={40}>
                          <Pie
                            data={genderCount}
                            cx="50%"
                            cy="50%"
                            labelLine={false}
                            // label={renderCustomizedLabel}
                            activeShape="22-00"
                            innerRadius={15}
                            outerRadius={80}
                            fill="#8884d8"
                            dataKey="value"
                          >
                            {genderCount.map((entry, index) => (
                              <Cell
                                key={`cell-${index}`}
                                fill={COLORS[index % COLORS.length]}
                              />
                            ))}
                            20000
                          </Pie>
                          <Tooltip />
                        </PieChartold>
                        <p className="totalgender">
                          {genderCount.reduce(function (prev, current) {
                            return prev + +current.value;
                          }, 0)}
                        </p>
                      </div>
                      <div className="">
                        {genderCount.map((item) => (
                          <>
                            <span
                              className={item.name}
                              style={{ color: item.background }}
                            ></span>{" "}
                            {item.name} : {item.value}
                            <br />
                          </>
                        ))}
                      </div>
                    </>
                  ) : (
                    <div className="">
                      <span className="male" style={{ color: "red" }}></span>{" "}
                      Male: 0
                      <br />
                      <span
                        className="female"
                        style={{ color: "blue" }}
                      ></span>{" "}
                      Female : 0
                    </div>
                  )}
                </div>
              </Col>
            </Row>
            <div className="row">
              <div className="col-md-12">
                <div className="dashboardsecondrow">
                  <div className="stat">
                    <div>
                      <h1 className="firstHeading">Registration Stats</h1>
                      <h1 className="secondHeading">
                        Today:{" "}
                        {moment(new Date())
                          .tz("Asia/Kolkata")
                          .format("YYYY-MM-DD") ===
                        registerState[registerState.length - 1]?.date
                          ? registerState[registerState.length - 1]?.count
                          : "0"}{" "}
                        Registrations
                      </h1>
                    </div>
                    <div>
                      <div className="meeting-cards ">
                        <div
                          className="meeting-detail domain"
                          onClick={handleShow}
                        >
                          <label className="filterbtn">+ Add Filter</label>
                        </div>
                      </div>
                      {
                        <Modal
                          show={popupstatus}
                          className="dashboardpopup"
                          // onHide={handleClose}
                          backdrop="static"
                          keyboard={false}
                        >
                          <div className="domain">
                            <h5 className="mb-4">Add Filters</h5>
                            <Form.Row>
                              <Col
                                className="form-group-col domain"
                                md={12}
                                controlId="oppotunityEndTime"
                              >
                                <Form.Label className="input-titlle">
                                  Domain-wise breakdown{" "}
                                  <span className="importantfield">*</span>
                                </Form.Label>
                                <div
                                  className="form-control  radiocss"
                                  name="domain"
                                  onChange={(e) =>
                                    selectFilter(e.target.value, e.target.name)
                                  }
                                >
                                  {domain.map((item, index) => (
                                    <Form.Check
                                      type="radio"
                                      value={item.value}
                                      label={item.name}
                                      name="domain"
                                      // checked={
                                      //   inputValue.gender === null
                                      //     ? null
                                      //     : inputValue.gender === "all"
                                      // }
                                    />
                                  ))}
                                </div>
                              </Col>
                            </Form.Row>
                            <Form.Row>
                              <Col
                                className="form-group-col "
                                md={12}
                                controlId="oppotunityEndTime"
                              >
                                <Form.Label className="input-titlle">
                                  Select College{" "}
                                  <span className="importantfield">*</span>
                                </Form.Label>
                                <div
                                  className="form-control  radiocss"
                                  name="sc"
                                >
                                  <Select
                                    mode="tags"
                                    size="middle"
                                    placeholder="Please select"
                                    // onChange={handleChange}
                                    onChange={handleChange}
                                    // onChange={(e) => selectFilter(e.target.value, e.target.name)}
                                    name="school"
                                    style={{
                                      width: "100%",
                                    }}
                                    options={collages}
                                  />
                                </div>
                              </Col>
                            </Form.Row>
                            <Form.Row className="twocolform">
                              <Col
                                className="form-group-col"
                                md={6}
                                controlId="formGridWebsite"
                              >
                                <Form.Label className="input-titlle">
                                  Select Year
                                </Form.Label>
                                <Form.Control
                                  as="select"
                                  name="year_of_study"
                                  onChange={(e) =>
                                    selectFilter(e.target.value, e.target.name)
                                  }
                                  // onChange={
                                  //   ((e) => onInputChange(e.target.value, e.target.name),
                                  //   (e) => setTeam(e.target.value))
                                  // }
                                  defaulValue="All"
                                  required
                                >
                                  <option value="all">All</option>
                                  <option value="1">1st year</option>
                                  <option value="2">2nd year</option>
                                  <option value="3">3rd year</option>
                                  <option value="4">4rth year</option>
                                  <option value="5">5th year</option>
                                </Form.Control>
                              </Col>

                              <Col
                                className="form-group-col"
                                md={6}
                                controlId="formGridOrganization"
                              >
                                <Form.Label className="input-titlle">
                                  Select Round{" "}
                                  <span className="importantfield">*</span>
                                </Form.Label>
                                <Form.Control
                                  as="select"
                                  name="current_round"
                                  onChange={(e) =>
                                    selectFilter(e.target.value, e.target.name)
                                  }
                                  // onChange={
                                  //   ((e) => onInputChange(e.target.value, e.target.name),
                                  //   (e) => setTeam(e.target.value))
                                  // }
                                  defaulValue="all"
                                  required
                                >
                                  <option value="all">All</option>
                                  {allRounds &&
                                    allRounds.map((round) => (
                                      <option value={round.title}>
                                        {round.title}
                                      </option>
                                    ))}
                                </Form.Control>
                              </Col>
                            </Form.Row>

                            <Form.Row className="twocolform">
                              <Col
                                className="form-group-col"
                                md={6}
                                controlId="formGridWebsite"
                              >
                                <Form.Label className="input-titlle">
                                  Select Gender
                                </Form.Label>
                                <Form.Control
                                  as="select"
                                  name="gender"
                                  onChange={(e) =>
                                    selectFilter(e.target.value, e.target.name)
                                  }
                                  // onChange={
                                  //   ((e) => onInputChange(e.target.value, e.target.name),
                                  //   (e) => setTeam(e.target.value))
                                  // }
                                  defaulValue="all"
                                  required
                                >
                                  <option value="all">All</option>
                                  <option value="male">Male</option>
                                  <option value="female">Female</option>
                                </Form.Control>
                              </Col>
                              <Col
                                className="form-group-col"
                                md={6}
                                controlId="formGridWebsite"
                              />
                            </Form.Row>
                            <div className="row">
                              <div className="col-md-12 text-right">
                                <button className="btn  " onClick={close}>
                                  Cancel
                                </button>
                                <button
                                  className="btn btn-danger"
                                  // onClick={() => {
                                  //   console.log("modal closed ");
                                  //   close();
                                  // }}
                                  onClick={() => submitFilter()}
                                >
                                  Apply Filter
                                </button>
                              </div>
                            </div>
                          </div>
                        </Modal>
                      }
                    </div>
                  </div>
                  <Form.Row className="registerState twocolform">
                    <Col
                      controlId="oppotunityEndDate"
                      className="form-group-col"
                      md={4}
                    >
                      <Form.Label className="input-titlle">
                        START DATE{" "}
                      </Form.Label>
                      <Form.Control
                        type="date"
                        placeholder="21-04-2021"
                        defaultValue={alldata?.opportunity?.start_date}
                        name="start_date"
                        onChange={(e) =>
                          dateFilter(e.target.value, e.target.name, "fromState")
                        }
                        // defaultValue={inputValue.end_date}
                        required
                      />
                    </Col>

                    <Col
                      className="form-group-col ml-0"
                      md={4}
                      controlId="oppotunityEndTime"
                    >
                      <Form.Label className="input-titlle">
                        END DATE{" "}
                      </Form.Label>
                      <Form.Control
                        type="date"
                        placeholder="21-04-2021"
                        defaultValue={formattedToday}
                        name="end_date"
                        onChange={(e) =>
                          dateFilter(e.target.value, e.target.name, "fromState")
                        }
                        // defaultValue={inputValue.end_time}
                        required
                      />
                    </Col>
                  </Form.Row>
                  {registerState.length > 0 ? (
                    <LineChart height={300} width={1000} data={registerState}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="date" padding={{ left: 70, right: 30 }} />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      {/* <Line dataKey="pv" stroke="#0BCCFE" activeDot={{ r: 8 }} /> */}
                      <Line
                        dataKey="count"
                        stroke="#FF0266"
                        activeDot={{ r: 8 }}
                      />
                    </LineChart>
                  ) : (
                    <div className="text-center">
                      <img src="https://iskconmumbai.com/images/notfound.png" />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="dashboardsecondrow">
                  <div className="stat">
                    <div>
                      <h1 className="firstHeading">Registration Insights</h1>
                    </div>
                  </div>
                  <div className="w-75">
                    {insightData && insightData.length > 0 ? (
                      <PieChart
                        style={{
                          fontFamily:
                            '"Nunito Sans", -apple-system, Helvetica, Arial, sans-serif',
                          fontSize: "6px",
                        }}
                        data={insightData}
                        radius={pieChartDefaultProps.radius - 6}
                        lineWidth={40}
                        segmentsStyle={{
                          transition: "stroke .3s",
                          cursor: "pointer",
                        }}
                        segmentsShift={(index) => (index === selected ? 6 : 1)}
                        animate
                        label={({ dataEntry }) =>
                          Math.round(dataEntry.percentage) + "%"
                        }
                        labelPosition={110 - lineWidth / 2}
                        labelStyle={{
                          fill: "#fff",
                          opacity: 0.75,
                          pointerEvents: "none",
                        }}
                        onClick={(_, index) => {
                          setSelected(index === selected ? undefined : index);
                        }}
                      />
                    ) : (
                      <div className="text-center pt-5 pb-5 mt-5">
                        <img src="https://iskconmumbai.com/images/notfound.png" />
                      </div>
                    )}
                  </div>
                  <div className="col-md-12">
                    <div className="row">
                      {insightData &&
                        insightData.map((item) => {
                          return (
                            <div className="col-md-6">
                              <p>
                                <span
                                  className="dot Pending"
                                  style={{ backgroundColor: item.color }}
                                ></span>{" "}
                                &nbsp; {item.title} &nbsp;
                                {item.value}
                              </p>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="dashboardsecondrow">
                  <div className="stat">
                    <div>
                      <h1 className="firstHeading">View Stats</h1>
                      <h1 className="secondHeading">
                        Today: {viewData[viewData.length - 1]?.uv} Views
                      </h1>
                    </div>
                    <div>
                      <div className="topboxflexViewState">
                        {topheader.map((item) => (
                          <span
                            className={
                              item.name === activeToptab
                                ? "active "
                                : "tabcount"
                            }
                            onClick={() => changeTab(item.name)}
                          >
                            {item.name}
                          </span>
                        ))}
                      </div>
                    </div>
                  </div>
                  <Form.Row className="twocolform">
                    <Col
                      controlId="oppotunityEndDate"
                      className="form-group-col"
                      md={6}
                    >
                      <Form.Label className="input-titlle">
                        START DATE{" "}
                      </Form.Label>
                      <Form.Control
                        type="date"
                        placeholder="21-04-2021"
                        defaultValue={alldata?.opportunity?.start_date}
                        name="start_date"
                        onChange={(e) =>
                          viewdateFilter(
                            e.target.value,
                            e.target.name,
                            "fromview",
                          )
                        }
                        // defaultValue={inputValue.end_date}
                        required
                      />
                    </Col>

                    <Col
                      className="form-group-col ml-0"
                      md={6}
                      controlId="oppotunityEndTime"
                    >
                      <Form.Label className="input-titlle">
                        END DATE{" "}
                      </Form.Label>
                      <Form.Control
                        type="date"
                        placeholder="00:00:00"
                        name="end_date"
                        defaultValue={formattedToday}
                        onChange={(e) =>
                          viewdateFilter(
                            e.target.value,
                            e.target.name,
                            "fromview",
                          )
                        }
                        // defaultValue={inputValue.end_time}
                        required
                      />
                    </Col>
                  </Form.Row>
                  {viewData.length > 0 ? (
                    <Area {...config2} />
                  ) : (
                    // <AreaChart
                    //   width={500}
                    //   height={400}
                    //   data={viewData}
                    //   margin={{
                    //     top: 10,
                    //     right: 30,
                    //     left: 0,
                    //     bottom: 0,
                    //   }}
                    // >
                    //   <CartesianGrid strokeDasharray="5 5" />
                    //   <XAxis dataKey="name" />
                    //   <YAxis />
                    //   <Tooltip />
                    //   <Area
                    //     type="monotone"
                    //     dataKey="totalviews"
                    //     stroke="#2D5BFF"
                    //     fill="#765DFF"
                    //   />
                    // </AreaChart>
                    <div className="text-center">
                      <img src="https://iskconmumbai.com/images/notfound.png" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Container>
        </div>
        {/* START CONTETN */}
      </div>
      {/* END PAGE CONTENT START */}
    </div>
  );
}

export default OpportunityDashboard;
