import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import Main from "./components/DynamicPages/Home/Main";
// import Opportunities from './components/StaticPages/Opportunities';
// import Testimonials from './components/DynamicPages/Testimonials';
import Aboutus from "./components/StaticPages/Aboutus";
import Contactus from "./components/StaticPages/Contactus";

import Opportunities from "./components/DynamicPages/Opportunities";
import OpportunityDetail from "./components/DynamicPages/OpportunityDetail";

import ManageOpportunity from "./components/DynamicPages/ManageOpportunity";
import { Register } from "./components/DynamicPages/Register";
import LogIn from "./components/DynamicPages/LogIn";
import Backendrouting from "./Backendrouter";
import Registrationform from "./components/StaticPages/Registrationform";
// import Userworkshop from './components/User/Workshoplist';
import Useropportunity from "./components/User/Useroppportunity";
import UserLayout from "./components/User/UserLayout";
import Termandcondition from "./components/StaticPages/Termcondition";
import Successmsg from "./components/StaticPages/Successmsg";
import Forgotpassword from "./components/User/Forgotpassword";
import Privacypolicy from "./components/StaticPages/Privacypolicy";
import Submitionlist from "./components/User/Submitionlist";
import Success from "./components/StaticPages/Success";
import CustomSuccess from "./components/StaticPages/CustomSuccess";
import AceTheCase from "./components/StaticPages/Acethecase";
import Thankyou from "./components/DynamicPages/thankyou";
import Quize from "./components/DynamicPages/Quize";
import QuizDetail from "./components/DynamicPages/Quiz/QuizDetail";
import Membersuccess from "./components/StaticPages/Membersuccess";
import opportunitySuccess from "./components/DynamicPages/FormSteps/opportunitySuccess";
import quizSuccess from "./components/DynamicPages/Quiz/quizSuccess";

function Frontendroutes() {
  return (
    <div className="app">
      <Router>
        <Switch>
          <Route exact path="/" component={Main} />
          <Route exact path="/password-reset/:id/:token" component={Main} />
          <Route exact path="/about" component={Aboutus} />
          <Route exact path="/contact-us" component={Contactus} />
          <Route exact path="/e/:id" component={OpportunityDetail} />
          <Route exact path="/opportunities" component={Opportunities} />
          <Route exact path="/register" component={Register} />
          <Route path="/user" component={UserLayout} />
          <Route exact path="/user/opportunity" component={Useropportunity} />
          <Route exact path="/login" component={LogIn} />
          <Route
            exact
            path="/terms-amd-conditions"
            component={Termandcondition}
          />
          <Route exact path="/privacy-policy" component={Privacypolicy} />
          <Route exact path="/successfully" component={Successmsg} />
          <Route exact path="/forgot-password" component={Forgotpassword} />
          <Route
            exact
            path="/manage/opportunity"
            component={ManageOpportunity}
          />
          <Route
            exact
            path="/manage/opportunity/success"
            component={opportunitySuccess}
          />
          <Route
            exact
            path="/user/event/submissions/:id"
            component={Submitionlist}
          />
          <Route exact path="/ace-the-case" component={AceTheCase} />
          <Route exact path="/payment-success" component={Thankyou} />
          <Route exact path="/e/:slug/round-1" component={QuizDetail} />

          <Route
            exact
            path="/e/HCCB-Disrupt-Season-1-38/round-1"
            component={QuizDetail}
          />
          <Route exact path="/e/HCCB-GET-44/round-1" component={QuizDetail} />
          <Route
            exact
            path="/e/HCCB-GET-Electrical-45/round-1"
            component={QuizDetail}
          />
          <Route
            exact
            path="/e/Quiz-Testing-102/round-1"
            component={QuizDetail}
          />
          <Route exact path="/quiz-finish" component={quizSuccess} />
          <Route
            exact
            path="/e/HCCB-Disrupt-Season-1-38/round-1/quiz"
            component={Quize}
          />
          <Route
            exact
            path="/e/Quiz-Testing-102/round-1/quiz"
            component={Quize}
          />
          <Route exact path="/e/HCCB-GET-44/round-1/quiz" component={Quize} />
          <Route
            exact
            path="/e/HCCB-GET-Electrical-45/round-1/quiz"
            component={Quize}
          />
          <Route
            exact
            path="/opportunities/detail/registration/:id"
            component={Registrationform}
          />
          <Route
            exact
            path="/e/HCCB-GET-Food-46/round-1"
            component={QuizDetail}
          />
          <Route
            exact
            path="/e/HCCB-GET-Food-46/round-1/quiz"
            component={Quize}
          />
          <Route
            exact
            path="/e/Food-Technology-57/round-1"
            component={QuizDetail}
          />
          <Route
            exact
            path="/e/Food-Technology-57/round-1/quiz"
            component={Quize}
          />
          <Route
            exact
            path="/e/Supply-Chain-Operations-Trainee-SCOT-Program-52/round-1"
            component={QuizDetail}
          />
          <Route
            exact
            path="/e/Supply-Chain-Operations-Trainee-(SCOT)-Program-52/round-1/quiz"
            component={Quize}
          />
          <Route
            exact
            path="/e/Instrumentation-56/round-1"
            component={QuizDetail}
          />
          <Route
            exact
            path="/e/Instrumentation-56/round-1/quiz"
            component={Quize}
          />
          <Route exact path="/e/Mechanical-55/round-1" component={QuizDetail} />
          <Route exact path="/e/Mechanical-55/round-1/quiz" component={Quize} />
          <Route
            exact
            path="/e/Microbiology-54/round-1"
            component={QuizDetail}
          />
          <Route
            exact
            path="/e/Microbiology-54/round-1/quiz"
            component={Quize}
          />
          <Route
            exact
            path="/e/SCOT-Electrical-53/round-1"
            component={QuizDetail}
          />
          <Route
            exact
            path="/e/SCOT-Electrical-53/round-1/quiz"
            component={Quize}
          />

          <Route path="/dashboard" component={Backendrouting} />
          <Route exact path="/success" component={Success} />
          <Route exact path="/custom-success" component={CustomSuccess} />
          <Route exact path="/register-successfull" component={Membersuccess} />
          <Route exact path="/acethecase" component={AceTheCase} />
        </Switch>
      </Router>
    </div>
  );
}

export default Frontendroutes;
