import { Select } from "antd";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { IoMdRemoveCircle } from "react-icons/io";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import config from "../CommonFiles/config.json";
import { trimObjectValues } from "../CommonFiles/Utils/ObjectUtils";
import "./CustomRegistrationForm.css";

const mainURL = Object.values(config["URL"]).join("");

function DynamicRegistrationForm({
  allColleges,
  formSettings,
  maxTeamSize,
  minTeamSize,
  opportunityId,
  updateRegistration,
  onAddOrg,
  onDeleteMember,
  isSameOrg,
  disabledMemberRemoval,
  regEndDateTime,
}) {
  const profileInfo = JSON.parse(localStorage.getItem("alldata")) ?? null;
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState(
    formSettings.reduce((acc, field) => {
      acc[field.fieldName] = field.fieldType === "file" ? null : "";
      return acc;
    }, {})
  );
  const [currentNoOfMembers, setCurrentNoOfMembers] = useState(minTeamSize);
  const [instituteSearchValue, setInstituteSearchValue] = useState("");
  const [orgSameAsLeader, setOrgSameAsLeader] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const teamNameField = formSettings.find(
    (field) => field.fieldName === "team_name"
  );

  const referralField = formSettings.find(
    (field) => field.fieldName === "referral_code"
  );

  // This is stop number fields from scrolling when the user scrolls the page
  useEffect(() => {
    const handleWheel = (e) => {
      e.preventDefault();
    };

    const numberInputs = document.querySelectorAll('input[type="number"]');

    numberInputs.forEach((input) => {
      input.addEventListener("wheel", handleWheel, { passive: false });
    });

    return () => {
      numberInputs.forEach((input) => {
        input.removeEventListener("wheel", handleWheel);
      });
    };
  }, []);

  useEffect(() => {
    if (updateRegistration) {
      setCurrentNoOfMembers(updateRegistration.length);
      setFormData(transformMembersToFormData(updateRegistration));
    }
  }, [updateRegistration]);

  // Set the member's name and email field value based on the profile info
  useEffect(() => {
    if (profileInfo && !updateRegistration) {
      setFormData((prevData) => ({
        ...prevData,
        members_email_1: profileInfo.email.toLowerCase(),
        members_name_1: `${profileInfo.first_name} ${profileInfo.last_name}`,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentNoOfMembers < minTeamSize) {
      setCurrentNoOfMembers(minTeamSize);
    }
  }, [currentNoOfMembers, minTeamSize]);

  const transformMembersToFormData = (membersArray) => {
    const transformedFormData = {};

    // The team name is the same for all members
    if (membersArray.length > 0) {
      transformedFormData["team_name"] = membersArray[0].team_name;
      transformedFormData["referral_code"] = membersArray[0].referral_code;
    }

    // Process each member and add their fields to transformedFormData
    membersArray.forEach((member, index) => {
      const memberIndex = index + 1; // Dynamic index starting from 1

      for (const [key, value] of Object.entries(member)) {
        if (key !== "team_id" && key !== "team_name") {
          transformedFormData[`${key}_${memberIndex}`] = value;
        }
      }
    });

    return transformedFormData;
  };

  const handleChange = (e, rules) => {
    const { name, value, type, checked, files } = e.target;

    setFormData((prevData) => {
      if (type === "checkbox") {
        const newValue = checked
          ? prevData[name]
            ? `${prevData[name]}, ${value}`
            : value
          : prevData[name]
              .split(", ")
              .filter((v) => v !== value)
              .join(", ");

        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "",
        }));

        return { ...prevData, [name]: newValue };
      } else if (type === "file") {
        const maxFileSize = rules?.find(
          (rule) => rule.maxFileSize
        )?.maxFileSize;
        if (
          files[0] &&
          maxFileSize &&
          files[0].size > maxFileSize * 1024 * 1024
        ) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: `File size exceeds the limit of ${maxFileSize} MB`,
          }));
          return prevData;
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: "",
          }));
          return { ...prevData, [name]: files[0] };
        }
      } else if (type === "number") {
        if (/^[0-9]*$/.test(value)) {
          const maxLengthRule = rules?.find(
            (rule) => rule.maxLength !== undefined
          );
          const minLengthRule = rules?.find(
            (rule) => rule.minLength !== undefined
          );

          if (minLengthRule && value.length < minLengthRule.minLength) {
            setErrors((prevErrors) => ({
              ...prevErrors,
              [name]: `Please enter a value with ${minLengthRule.minLength} digits`,
            }));
          } else {
            if (name.startsWith("members_mobile_no_")) {
              // Check for duplicate mobile numbers
              const isDuplicate = Object.keys(prevData).some(
                (key) =>
                  key.startsWith("members_mobile_no_") &&
                  key !== name &&
                  prevData[key] === value
              );

              if (isDuplicate) {
                setErrors((prevErrors) => ({
                  ...prevErrors,
                  [name]: "This mobile no. is already used for another member",
                }));
              } else {
                setErrors((prevErrors) => ({
                  ...prevErrors,
                  [name]: "",
                }));
              }
            } else {
              setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: "",
              }));
            }
          }

          if (maxLengthRule && value.length > maxLengthRule.maxLength) {
            return prevData;
          } else {
            return { ...prevData, [name]: value };
          }
        } else {
          return prevData;
        }
      } else if (type === "email") {
        if (/\S+@\S+\.\S+/.test(value.toLowerCase())) {
          // Check for duplicate emails
          const isDuplicate = Object.keys(prevData).some(
            (key) =>
              key.startsWith("members_email_") &&
              key !== name &&
              prevData[key] === value.toLowerCase()
          );

          if (isDuplicate) {
            setErrors((prevErrors) => ({
              ...prevErrors,
              [name]: "This email is already used for another member",
            }));
          } else {
            setErrors((prevErrors) => ({
              ...prevErrors,
              [name]: "",
            }));
          }
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: "Please enter a valid email address",
          }));
        }

        return { ...prevData, [name]: value.toLowerCase() };
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "",
        }));
        return { ...prevData, [name]: value };
      }
    });

    if (rules && type !== "file") {
      const maxLengthRule = rules.find((rule) => rule.maxLength !== undefined);
      const minLengthRule = rules.find((rule) => rule.minLength !== undefined);

      if (maxLengthRule && value.length > maxLengthRule.maxLength) {
        return;
      }

      if (minLengthRule && value.length < minLengthRule.minLength) {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: `Minimum length required for this field is ${minLengthRule.minLength}`,
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "",
        }));
      }
    }
  };

  const handleInstituteChange = (selectedValue, memberIndex) => {
    if (!isSameOrg) {
      setFormData((prevData) => ({
        ...prevData,
        [`institute_organization_${memberIndex}`]: selectedValue,
      }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        [`institute_organization_${memberIndex}`]: "",
      }));

      orgSameAsLeader.includes(memberIndex) &&
        setOrgSameAsLeader(
          orgSameAsLeader.filter((item) => item !== memberIndex)
        );
    } else {
      setFormData((prevData) => {
        const newData = { ...prevData };
        for (let i = 1; i <= currentNoOfMembers; i++) {
          newData[`institute_organization_${i}`] = selectedValue;
        }
        return newData;
      });
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        for (let i = 1; i <= currentNoOfMembers; i++) {
          newErrors[`institute_organization_${i}`] = "";
        }
        return newErrors;
      });
    }
  };

  const handleCreateOrg = (newOrg, memberIndex) => {
    axios({
      method: "post",
      url: mainURL + "/collages",
      data: {
        name: newOrg,
      },
    })
      .then((response) => {
        if (response.data.status === 1) {
          onAddOrg(newOrg);
          setFormData((prevData) => ({
            ...prevData,
            [`institute_organization_${memberIndex}`]: newOrg,
          }));
          setErrors((prevErrors) => ({
            ...prevErrors,
            [`institute_organization_${memberIndex}`]: "",
          }));
        } else {
          Swal.fire(
            "Error",
            "Something went wrong. Please try again later.",
            "error"
          );
        }
      })
      .catch(function (response) {
        //handle error
        console.log("Error while calling api", response);
      });
  };

  const handleAddTeamMember = (e) => {
    e.preventDefault();
    setCurrentNoOfMembers(currentNoOfMembers + 1);

    if (
      isSameOrg &&
      formData.institute_organization_1 &&
      formData.institute_organization_1 !== ""
    ) {
      setFormData((prevData) => ({
        ...prevData,
        [`institute_organization_${currentNoOfMembers + 1}`]:
          formData.institute_organization_1,
      }));
    }
  };

  const handleRemoveMember = (index) => {
    setFormData((prevData) => {
      // Create a new object without the fields of the member to be removed
      const newData = Object.keys(prevData).reduce((acc, key) => {
        if (!key.endsWith(`_${index}`)) {
          acc[key] = prevData[key];
        }
        return acc;
      }, {});

      // Adjust the indices of the remaining members
      for (let i = index + 1; i <= currentNoOfMembers; i++) {
        Object.keys(newData).forEach((key) => {
          if (key.endsWith(`_${i}`)) {
            const newKey = key.replace(`_${i}`, `_${i - 1}`);
            newData[newKey] = newData[key];
            delete newData[key];
          }
        });
      }

      return newData;
    });

    setCurrentNoOfMembers((prevCount) => prevCount - 1);
  };

  const handleRemoveMemberFromDB = (memberIndex) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(
            `${mainURL}/opportunity/register/${updateRegistration[memberIndex].id}`
          )
          .then((response) => {
            if (response.data.status === 1) {
              handleRemoveMember(memberIndex + 1);
              onDeleteMember(updateRegistration[memberIndex].id);
              Swal.fire(
                "Deleted!",
                "The member has been informed about this change.",
                "success"
              );
            } else {
              Swal.fire(
                "Error",
                "Something went wrong. Please try again.",
                "error"
              );
            }
          })
          .catch(function (response) {
            //handle error
            console.log("Error while calling api", response);
          });
      }
    });
  };

  const handleSameOrgCheckboxChange = (e, memberIndex) => {
    if (e.target.checked) {
      setOrgSameAsLeader([...orgSameAsLeader, memberIndex]);
      setFormData((prevData) => ({
        ...prevData,
        [`institute_organization_${memberIndex}`]:
          prevData[`institute_organization_1`],
      }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        [`institute_organization_${memberIndex}`]: "",
      }));
    } else {
      setOrgSameAsLeader(
        orgSameAsLeader.filter((item) => item !== memberIndex)
      );
      setFormData((prevData) => ({
        ...prevData,
        [`institute_organization_${memberIndex}`]: "",
      }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        [`institute_organization_${memberIndex}`]: "Please fill out this field",
      }));
    }
  };

  const renderField = (field, memberIndex) => {
    if (field.fieldName === "team_name") return;

    const fieldValue = formData[field.fieldName] || "";

    switch (field.fieldType) {
      case "text":
      case "email":
      case "number":
        return (
          <input
            className="form-control"
            type={field.fieldType}
            name={field.fieldName}
            placeholder={field.placeholder}
            onChange={(e) => handleChange(e, field?.rules)}
            value={fieldValue}
            onKeyDown={(e) =>
              field.fieldType === "number" &&
              (e.key === "ArrowUp" || e.key === "ArrowDown") &&
              e.preventDefault()
            }
            disabled={
              updateRegistration
                ? memberIndex <= updateRegistration.length
                  ? field.fieldName === `members_email_${memberIndex}`
                  : false
                : field.fieldName === "members_email_1"
            }
          />
        );
      case "radio":
        return (
          <div className="radio-field">
            {field.options.map((option, index) => (
              <label key={index}>
                <input
                  type="radio"
                  name={field.fieldName}
                  value={option}
                  onChange={(e) => handleChange(e, field?.rules)}
                  checked={fieldValue === option}
                />
                {option}
              </label>
            ))}
          </div>
        );
      case "textarea":
        return (
          <textarea
            className="form-control"
            name={field.fieldName}
            placeholder={field.placeholder}
            onChange={(e) => handleChange(e, field?.rules)}
            value={fieldValue}
            maxLength={field.rules?.find((rule) => rule.maxLength)?.maxLength}
          />
        );
      case "checkbox":
        return (
          <div className="custom-field-checkbox-container">
            {field.options.map((option, index) => (
              <label key={index} className="custom-field-checkbox-item">
                <input
                  type="checkbox"
                  name={field.fieldName}
                  value={option}
                  className="form-control custom-field-checkbox"
                  onChange={(e) => handleChange(e, field?.rules)}
                  checked={fieldValue && fieldValue.includes(option)}
                />
                {option}
              </label>
            ))}
          </div>
        );

      case "select":
        if (field.fieldName === `institute_organization_${memberIndex}`) {
          return (
            <Select
              className="institute-list-dropdown"
              disabled={isSameOrg ? memberIndex !== 1 : false}
              name={field.fieldName}
              onChange={(value) => handleInstituteChange(value, memberIndex)}
              onSearch={(value) => setInstituteSearchValue(value)}
              value={fieldValue}
              bordered={false}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children?.toLowerCase().includes(input?.toLowerCase())
              }
              notFoundContent={
                instituteSearchValue.trim().length > 0 &&
                !field.options &&
                !field.excluded_options
                  .map((item) => item.toLowerCase().replace(/\s+/g, ""))
                  .includes(
                    instituteSearchValue.toLowerCase().replace(/\s+/g, "")
                  ) && (
                  <Button
                    type="text"
                    onClick={() =>
                      handleCreateOrg(instituteSearchValue, memberIndex)
                    }
                  >
                    Create "{instituteSearchValue}"
                  </Button>
                )
              }
            >
              <Select.Option value="">Select an option</Select.Option>
              {
                // All Institutes or Exclude Some From All
                field.options.length === 0 &&
                  allColleges
                    .map((c) => c.label)
                    .filter(
                      (item) =>
                        !field.excluded_options
                          .map((eo) => eo.toLowerCase().replace(/\s+/g, ""))
                          .includes(item.toLowerCase().replace(/\s+/g, "")) &&
                        item?.trim().length !== 0
                    )
                    .map((option, index) => (
                      <Select.Option key={index} value={option}>
                        {option}
                      </Select.Option>
                    ))
              }
              {
                // Only selected institutes
                field.options.length > 0 &&
                  field.options.map((option, index) => (
                    <Select.Option key={index} value={option}>
                      {option}
                    </Select.Option>
                  ))
              }
            </Select>
          );
        } else {
          return (
            <select
              className="form-control"
              name={field.fieldName}
              onChange={(e) => handleChange(e, field?.rules)}
              value={fieldValue}
            >
              <option value="">Select an option</option>
              {field.options.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>
          );
        }

      case "file":
        return (
          <input
            className="form-control"
            type="file"
            name={field.fieldName}
            onChange={(e) => handleChange(e, field?.rules)}
          />
        );
      default:
        return null;
    }
  };

  const renderTeamMemberFields = (memberIndex) => {
    return formSettings.map((item) => {
      if (item.fieldName === "team_name" || item.parentGroup) return null;

      const dynamicFieldName = `${item.fieldName}_${memberIndex}`;
      const dynamicField = { ...item, fieldName: dynamicFieldName };

      return (
        <div className="input-field" key={dynamicFieldName}>
          <div className="field-label-container">
            <label>
              {item.label}
              {item.isMandatory && <span className="required-mark"> *</span>}
            </label>

            {/* Checkbox to select 'Same Organization as Leader' */}
            {!isSameOrg &&
              item.fieldName === "institute_organization" &&
              memberIndex !== 1 && (
                <label className="same-org-checkbox">
                  <input
                    type="checkbox"
                    className="form-control custom-field-checkbox"
                    disabled={
                      !formData.institute_organization_1 ||
                      formData.institute_organization_1 === ""
                    }
                    onChange={(e) =>
                      handleSameOrgCheckboxChange(e, memberIndex)
                    }
                    checked={orgSameAsLeader.includes(memberIndex)}
                  />
                  Same as leader
                </label>
              )}
          </div>
          {renderField(dynamicField, memberIndex)}
          {errors[dynamicFieldName] && (
            <span className="required-mark">{errors[dynamicFieldName]}</span>
          )}
        </div>
      );
    });
  };

  const handleSubmit = () => {
    const cleanedFormData = trimObjectValues(formData);
    setFormData(cleanedFormData);

    // Check if there are any errors in the errors object
    if (Object.values(errors).some((error) => error !== "")) {
      return;
    }

    // Collect all mandatory fields
    const requiredFields = formSettings
      .filter((field) => field.isMandatory)
      .map((field) => field.fieldName);

    const fieldsWithError = [];

    // Check for missing fields dynamically for each team member
    requiredFields.forEach((fieldName) => {
      if (!fieldName.includes("team_name")) {
        // Dynamic fields
        for (let i = 1; i <= currentNoOfMembers; i++) {
          const dynamicFieldName = `${fieldName}_${i}`;
          if (
            !cleanedFormData[dynamicFieldName] ||
            cleanedFormData[dynamicFieldName] === ""
          ) {
            fieldsWithError.push(dynamicFieldName);
          }
        }
      } else {
        // Team name field
        if ((!cleanedFormData[fieldName] || cleanedFormData[fieldName] === "") && maxTeamSize != 1) {
          fieldsWithError.push(fieldName);
        }
      }
    });

    if (fieldsWithError.length > 0) {
      // Construct new errors object
      const newErrors = fieldsWithError.reduce((acc, fieldName) => {
        acc[fieldName] = "Please fill out this field";
        return acc;
      }, {});
      setErrors((prevErrors) => ({
        ...prevErrors,
        ...newErrors,
      }));
      return;
    }

    // Process form data
    const team_id = updateRegistration
      ? updateRegistration[0].team_id
      : Math.random().toString(36).substr(2, 9);
    const team_name = cleanedFormData["team_name"];
    const referral_code = cleanedFormData["referral_code"];
    const membersData = [];

    for (let i = 1; i <= currentNoOfMembers; i++) {
      let member = {};

      if (updateRegistration) {
        member = {
          id: updateRegistration[i - 1] ? updateRegistration[i - 1].id : null,
          team_id,
          team_name,
          referral_code,
        };
      } else {
        member = {
          team_id,
          team_name,
          referral_code,
        };
      }

      formSettings.forEach((field) => {
        const fieldName = `${field.fieldName}_${i}`;
        if (cleanedFormData[fieldName] !== undefined) {
          member[field.fieldName] = cleanedFormData[fieldName];
        }
      });

      membersData.push(member);
    }

    validateEmails(membersData);
  };

  const validateEmails = (members) => {
    const USER_TOKEN = localStorage.getItem("token");

    if (!USER_TOKEN) {
      Swal.fire("Token Expired!", "Please login again", "error");
      return;
    } else {
      setIsLoading(true);
      const AuthStr = "Bearer ".concat(USER_TOKEN);
      const emailsToValidate = updateRegistration
        ? members
            .map((member) => member.members_email)
            .filter(
              (email) =>
                !updateRegistration
                  .map((member) => member.members_email)
                  .includes(email)
            )
        : members.map((member) => member.members_email);

      if (emailsToValidate.length === 0) {
        submitData(members);
      } else {
        axios({
          method: "post",
          url: mainURL + `/v1/opportunity/${opportunityId}/isRegistered`,
          data: { emails: emailsToValidate },
          headers: { Authorization: AuthStr },
        })
          .then((response) => {
            if (response.data.emails.length === 0) {
              submitData(members);
            } else {
              setIsLoading(false);
              let existingEmails = [];

              for (let key in formData) {
                if (
                  key.startsWith("members_email") &&
                  response.data.emails.includes(formData[key])
                ) {
                  existingEmails.push(key);
                }
              }

              if (existingEmails.length > 0) {
                existingEmails.forEach((key) => {
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    [key]: "Email already registered",
                  }));
                });
              }
            }
          })
          .catch(function (error) {
            setIsLoading(false);
            console.error(error);
          });
      }
    }
  };

  const submitData = (members) => {
    const USER_TOKEN = localStorage.getItem("token");
    const AuthStr = "Bearer ".concat(USER_TOKEN);

    if (moment(regEndDateTime).isAfter(moment())) {
      axios({
        method: `${updateRegistration ? "put" : "post"}`,
        url: mainURL + `/opportunity/${opportunityId}/registration`,
        data: { opportunityId, members },
        headers: { Authorization: AuthStr },
      })
        .then((response) => {
          setIsLoading(false);
          if (response.data.status === 1) {
            localStorage.setItem("memberData", JSON.stringify(members));
            if (updateRegistration) {
              Swal.fire({
                title: "Good job!",
                text: "Team details have been updated!",
                type: "success",
              }).then(function () {
                history.push("/user");
              });
            } else {
              history.push(`/custom-success`);
            }
          } else {
            Swal.fire(
              `${updateRegistration ? "Team details update" : "Registration"} Failed!`,
              "Please try again",
              "error"
            );
          }
        })
        .catch(function (error) {
          setIsLoading(false);
          console.error(error);
        });
    } else {
      Swal.fire(
        "Registration Closed!",
        "No submission / modification is allowed at this time",
        "error"
      );
    }
  };

  return (
    <div>
      <form className="form-body">
        {!isLoading && profileInfo && (
          <>
            <div className="form-row">
              <h3>Register for Opportunity</h3>
            </div>
            <br />

            {/* Team Name at the top */}
            {teamNameField && (maxTeamSize !== 1) && (
              <div
                className="main-registration-form-container"
                id="team-name-field-container"
              >
                <div className="input-field">
                  <div className="field-label-container">
                    <label>
                      {teamNameField.label}
                      {teamNameField.isMandatory ? (
                        <span className="required-mark"> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                  <input
                    className="form-control"
                    type={teamNameField.fieldType}
                    name={teamNameField.fieldName}
                    placeholder={teamNameField.placeholder}
                    onChange={(e) => handleChange(e, teamNameField?.rules)}
                    value={formData[teamNameField.fieldName]}
                  />
                  {errors[teamNameField.fieldName] !== "" && (
                    <span className="required-mark">
                      {errors[teamNameField.fieldName]}
                    </span>
                  )}
                </div>
              </div>
            )}

            {/* Member wise Registration Form */}
            {Array.from({ length: currentNoOfMembers }).map((_, index) => (
              <React.Fragment key={index}>
                {/* Team Fields Headings */}
                {maxTeamSize > 1 && index === 0 && (
                  <h5 className="prizetittle leader">Leader's Details</h5>
                )}

                {maxTeamSize > 1 && index > 0 && (
                  <h5 className="prizetittle member">
                    Member {index + 1} Details
                  </h5>
                )}
                {/* Team Fields Headings End */}

                <div className="main-registration-form-container">
                  {renderTeamMemberFields(index + 1)}

                  {/* Remove Button to Delete Member Record from UI */}
                  {index + 1 > minTeamSize &&
                    (updateRegistration
                      ? index + 1 > updateRegistration.length
                      : true) && (
                      <div className="remove-team-member-btn-section">
                        <button
                          className="remove-team-member-btn"
                          onClick={(e) => {
                            e.preventDefault();
                            handleRemoveMember(index + 1);
                          }}
                        >
                          Remove <IoMdRemoveCircle />
                        </button>
                      </div>
                    )}

                  {/* Remove Button to Delete Member Record from DB */}
                  {updateRegistration &&
                    index > 0 &&
                    index + 1 <= updateRegistration.length && (
                      <div className="remove-team-member-btn-section">
                        <button
                          className="remove-team-member-btn"
                          disabled={disabledMemberRemoval}
                          onClick={(e) => {
                            e.preventDefault();
                            handleRemoveMemberFromDB(index);
                          }}
                        >
                          Remove <IoMdRemoveCircle />
                        </button>
                      </div>
                    )}
                </div>
              </React.Fragment>
            ))}
            {/* Member wise Registration Form End */}

            {/* Referral Field Here */}
            {referralField && (
              <div
                className="main-registration-form-container"
                id="team-name-field-container"
              >
                <div className="input-field">
                  <div className="field-label-container">
                    <label>
                      {referralField.label}
                      {referralField.isMandatory ? (
                        <span className="required-mark"> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                  <input
                    className="form-control"
                    type={referralField.fieldType}
                    name={referralField.fieldName}
                    placeholder={referralField.placeholder}
                    disabled={updateRegistration ? true : false}
                    onChange={(e) => handleChange(e, referralField?.rules)}
                    value={formData[referralField.fieldName]}
                  />
                  {errors[referralField.fieldName] !== "" && (
                    <span className="required-mark">
                      {errors[referralField.fieldName]}
                    </span>
                  )}
                </div>
              </div>
            )}

            {currentNoOfMembers < maxTeamSize && (
              <div className="form-row">
                <button
                  className="add-team-member-btn"
                  onClick={handleAddTeamMember}
                >
                  <i className="fa fa-plus"></i> Add Member
                </button>
              </div>
            )}
          </>
        )}
        {isLoading && (
          <div className="reg-form-loader-container">
            <div className="reg-form-loader"></div>
          </div>
        )}
      </form>
      {!isLoading && (
        <div className="custom-reg-form-footer mt-4">
          <Button
            className="custom-reg-form save-hosting-btn"
            type="button"
            onClick={handleSubmit}
          >
            {updateRegistration ? "Update details" : "Register for Opportunity"}
          </Button>
        </div>
      )}
    </div>
  );
}

export default DynamicRegistrationForm;
