import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import {
  IoIosCheckbox,
  IoIosRadioButtonOn,
  IoMdArrowDropdownCircle,
  IoMdTrash,
} from "react-icons/io";
import Tooltip from "../../CommonFiles/UI Elements/Tooltip/Tooltip";
import "../CustomRegistrationForm.css";

const CustomFieldModal = ({
  allColleges,
  isOpen,
  editingField,
  existingFields,
  onClose,
  onSave,
}) => {
  const [fieldType, setFieldType] = useState("");
  const [fieldLabel, setFieldLabel] = useState("");
  const [fieldName, setFieldName] = useState("");
  const [fieldNameError, setFieldNameError] = useState("");
  const [fieldIsMandatory, setFieldIsMandatory] = useState(true);
  const [fieldPlaceholder, setFieldPlaceholder] = useState("");
  const [fieldMaxLength, setFieldMaxLength] = useState("");
  const [fieldMinLength, setFieldMinLength] = useState("");
  const [fieldMaxLengthError, setFieldMaxLengthError] = useState("");
  const [fieldMinLengthError, setFieldMinLengthError] = useState("");
  const [fieldMaxFileSize, setFieldMaxFileSize] = useState(50);
  const [fieldMaxFileSizeError, setFieldMaxFileSizeError] = useState("");
  const [options, setOptions] = useState([""]);
  const [excludedOptions, setExcludedOptions] = useState([]);
  const [selectedOptionSettings, setSelectedOptionSettings] = useState("All");
  const [acceptText, setAcceptText] = useState("");
  const [saveError, setSaveError] = useState("");
  const [filteredInstituteSuggestions, setFilteredInstituteSuggestions] =
    useState({});

  useEffect(() => {
    if (editingField) {
      setFieldType(
        editingField.isTnCField ? "checkbox-text" : editingField.fieldType
      );
      setFieldLabel(editingField.label);
      setFieldName(editingField.fieldName);
      setFieldIsMandatory(editingField.isMandatory);
      setFieldPlaceholder(editingField.placeholder ?? "");
      setFieldMaxLength(
        editingField.rules?.find((r) => r.maxLength !== undefined)
          ? editingField.rules?.find((r) => r.maxLength !== undefined).maxLength
          : ""
      );
      setFieldMinLength(
        editingField.rules?.find((r) => r.minLength !== undefined)
          ? editingField.rules?.find((r) => r.minLength !== undefined).minLength
          : ""
      );
      setFieldMaxFileSize(
        editingField.rules?.find((r) => r.maxFileSize !== undefined)
          ? editingField.rules?.find((r) => r.maxFileSize !== undefined)
              .maxFileSize
          : ""
      );
      setOptions(editingField.isTnCField ? [""] : editingField.options ?? [""]);
      setSelectedOptionSettings(
        editingField.fieldName === "institute_organization"
          ? editingField.options.length === 0 &&
            editingField.excluded_options.length === 0
            ? "All"
            : editingField.options.length > 0
              ? "Eligible Organizations"
              : "Non Eligible Organizations"
          : "All"
      );
      setExcludedOptions(editingField.excluded_options ?? []);
      setAcceptText(editingField.isTnCField ? editingField.options[0] : "");
    }
  }, [editingField]);

  // Monitor field name change
  useEffect(() => {
    if (
      (!editingField || editingField.fieldName !== fieldName) &&
      existingFields.find((field) => field.fieldName === fieldName)
    ) {
      setFieldNameError("You have already added a field with similar label");
    } else {
      setFieldNameError("");
    }
  }, [fieldName, existingFields, editingField]);

  function convertToFieldName(label) {
    const trimmedLabel = label.trim();
    const alphanumericLabel = trimmedLabel.replace(/[^a-zA-Z0-9\s]/g, "");
    const fieldName = alphanumericLabel.replace(/\s+/g, "_").toLowerCase();

    return fieldName;
  }

  const handleFieldNameChange = (e) => {
    const value = e.target.value;
    const regex = /^[a-zA-Z0-9]*$/; // Alphanumeric without spaces
    if (regex.test(value)) {
      if (
        (!editingField || editingField.fieldName !== fieldName) &&
        existingFields.find((field) => field.fieldName === value)
      ) {
        setFieldName(value);
        setFieldNameError("This field name is already in use");
      } else {
        setFieldName(value);
        setFieldNameError("");
      }
    } else {
      setFieldNameError("Field name must be alphanumeric and without spaces");
    }
  };

  const handleLengthChange = (e, type) => {
    const value = e.target.value;
    const regex = /^[0-9]*$/; // Numeric without spaces

    if (value === "") {
      if (type === "min") {
        setFieldMinLengthError("");
        setFieldMinLength("");
      } else {
        setFieldMaxLengthError("");
        setFieldMaxLength("");
      }
    } else {
      if (regex.test(value)) {
        if (type === "min") {
          if (fieldMaxLength !== "" && Number(value) > Number(fieldMaxLength)) {
            setFieldMinLength(value);
            setFieldMinLengthError(
              "Min Length must be less than or equal to Max Length"
            );
            setFieldMaxLengthError("");
          } else {
            setFieldMinLength(value);
            setFieldMinLengthError("");
            setFieldMaxLengthError("");
          }
        } else {
          if (fieldMinLength !== "" && Number(value) < Number(fieldMinLength)) {
            setFieldMaxLength(value);
            setFieldMaxLengthError(
              "Max Length must be greater than or equal to Min Length"
            );
            setFieldMinLengthError("");
          } else {
            setFieldMaxLength(value);
            setFieldMaxLengthError("");
            setFieldMinLengthError("");
          }
        }
      } else {
        type === "min"
          ? setFieldMinLengthError(
              "Min Length must be number and without spaces"
            )
          : setFieldMaxLengthError(
              "Max Length must be number and without spaces"
            );
      }
    }
  };

  const handleMaxFileSizeChange = (e) => {
    const value = e.target.value;
    const regex = /^[0-9]*$/; // Numeric without spaces
    if (regex.test(value) && value > 0) {
      setFieldMaxFileSize(value);
      setFieldMaxFileSizeError("");
    } else {
      setFieldMaxFileSizeError(
        "Max file size must be number in MB and more than 0"
      );
    }
  };

  const handleOptionChange = (e, index) => {
    const array =
      fieldName === "institute_organization" &&
      selectedOptionSettings === "Non Eligible Organisations"
        ? excludedOptions.concat()
        : options.concat();

    array[index] = e.target.value;

    if (
      fieldName === "institute_organization" &&
      selectedOptionSettings === "Non Eligible Organisations"
    ) {
      setExcludedOptions(array);
    } else {
      setOptions(array);
    }

    if (
      fieldName === "institute_organization" &&
      e.target.value.trim().length > 2
    ) {
      const suggestedInstitutes = allColleges
        .map((institute) => institute.label)
        .filter((institute) =>
          institute.toLowerCase().includes(e.target.value.trim().toLowerCase())
        );

      setFilteredInstituteSuggestions({
        index,
        suggestions: suggestedInstitutes,
      });
    } else {
      setFilteredInstituteSuggestions({});
    }
  };

  const handleInstituteSuggestionClick = (suggestion, index) => {
    const array =
      fieldName === "institute_organization" &&
      selectedOptionSettings === "Non Eligible Organisations"
        ? excludedOptions.concat()
        : options.concat();

    array[index] = suggestion;

    if (
      fieldName === "institute_organization" &&
      selectedOptionSettings === "Non Eligible Organisations"
    ) {
      setExcludedOptions(array);
    } else {
      setOptions(array);
    }

    setFilteredInstituteSuggestions({});
  };

  const resetAllFields = () => {
    setFieldLabel("");
    setFieldName("");
    setFieldNameError("");
    setFieldPlaceholder("");
    setFieldMaxLength("");
    setFieldMaxLengthError("");
    setFieldMaxFileSize(50);
    setFieldMaxFileSizeError("");
    setOptions([""]);
    setExcludedOptions([]);
    setAcceptText("");
    setFilteredInstituteSuggestions({});
  };

  const handleSaveField = () => {
    if (fieldNameError || fieldMaxLengthError || fieldMaxFileSizeError) {
      return;
    }

    if (
      fieldType.length === 0 ||
      fieldLabel.trim().length === 0 ||
      fieldName.trim().length === 0
    ) {
      setSaveError("Please fill all the necessary fields");
      return;
    }

    if (
      (fieldType === "radio" || fieldType === "checkbox") &&
      options.find((option) => option.trim().length === 0) !== undefined
    ) {
      setSaveError("Please fill all the necessary fields");
      return;
    }

    if (
      fieldType === "select" &&
      fieldName !== "institute_organization" &&
      options.find((option) => option.trim().length === 0) !== undefined
    ) {
      setSaveError("Please fill all the necessary fields");
      return;
    }

    if (
      fieldType === "select" &&
      fieldName === "institute_organization" &&
      selectedOptionSettings === "Eligible Organizations" &&
      options.find((option) => option.trim().length === 0) !== undefined
    ) {
      setSaveError("Please fill all the necessary fields");
      return;
    }

    if (
      fieldType === "select" &&
      fieldName === "institute_organization" &&
      selectedOptionSettings === "Non Eligible Organisations" &&
      excludedOptions.find((option) => option.trim().length === 0) !== undefined
    ) {
      setSaveError("Please fill all the necessary fields");
      return;
    }

    if (fieldType === "file" && fieldMaxFileSize < 1) {
      setSaveError("Please fill all the necessary fields");
      return;
    }

    if (fieldType === "checkbox-text" && acceptText.trim().length === 0) {
      setSaveError("Please fill all the necessary fields");
      return;
    }

    setSaveError("");

    let newField = {
      label: fieldLabel.trimStart().trimEnd(),
      fieldType: fieldType === "checkbox-text" ? "checkbox" : fieldType,
      fieldName: fieldName,
      isMandatory: fieldIsMandatory,
    };

    if (
      fieldType === "text" ||
      fieldType === "number" ||
      fieldType === "textarea"
    ) {
      if (fieldPlaceholder.trim().length > 0) {
        newField = {
          ...newField,
          placeholder: fieldPlaceholder.trimStart().trimEnd(),
        };
      }

      if (Number(fieldMaxLength) > 0) {
        newField = {
          ...newField,
          rules:
            newField.rules?.length > 0
              ? [...newField.rules, { maxLength: fieldMaxLength }]
              : [{ maxLength: fieldMaxLength }],
        };
      }

      if (Number(fieldMinLength) > 0) {
        newField = {
          ...newField,
          rules:
            newField.rules?.length > 0
              ? [...newField.rules, { minLength: fieldMinLength }]
              : [{ minLength: fieldMinLength }],
        };
      }
    }

    if (
      fieldType === "radio" ||
      fieldType === "checkbox" ||
      fieldType === "select"
    ) {
      newField = {
        ...newField,
        options,
      };
    }

    if (fieldName === "institute_organization") {
      newField = {
        ...newField,
        excluded_options: excludedOptions,
      };
    }

    if (fieldType === "checkbox-text") {
      newField = {
        ...newField,
        options: [acceptText],
        isTnCField: true,
      };
    }

    if (fieldType === "file") {
      newField = {
        ...newField,
        rules: [{ maxFileSize: fieldMaxFileSize }],
      };
    }

    onSave(newField);
    setFieldType("");
    resetAllFields();
    onClose();
  };

  if (!isOpen) {
    return null;
  } else {
    return ReactDOM.createPortal(
      <div className="modal-overlay">
        <div className="custom-field-modal">
          <div className="custom-field-modal-body">
            <h4>
              {fieldName === "institute_organization"
                ? "Institute / Organization Field Settings"
                : "Add Custom Field"}
            </h4>
            <label className="is-mandatory-field">
              Is this field mandatory ?
              <input
                type="checkbox"
                className="form-control is-mandatory"
                checked={fieldIsMandatory}
                onChange={(e) => setFieldIsMandatory(e.target.checked)}
              />
            </label>
            {fieldName !== "institute_organization" && (
              <>
                <label>
                  Field Type<span className="required-mark"> *</span>
                </label>
                <select
                  className="form-control"
                  value={fieldType}
                  onChange={(e) => {
                    setFieldType(e.target.value);
                    resetAllFields();
                  }}
                >
                  <option value="" disabled>
                    Select Field Type
                  </option>
                  <option value="text">Short Text</option>
                  <option value="textarea">Long Text (Text Area)</option>
                  <option value="number">Number</option>
                  <option value="radio">Radio Button</option>
                  <option value="checkbox">Check Box</option>
                  <option value="select">Drop Down</option>
                  {/* <option value="file">File Upload</option> */}
                  <option value="checkbox-text">
                    Accept Terms / Conditions
                  </option>
                </select>

                {/* Common Properties Start*/}
                <label>
                  Field Label<span className="required-mark"> *</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Enter label to show for the field"
                  value={fieldLabel}
                  onChange={(e) => {
                    setFieldName(convertToFieldName(e.target.value));
                    setFieldLabel(e.target.value);
                  }}
                />
                {fieldNameError && (
                  <span className="required-mark">{fieldNameError}</span>
                )}

                <input
                  aria-hidden={true}
                  className="input-hidden"
                  type="text"
                  value={fieldName}
                  onChange={handleFieldNameChange}
                />
              </>
            )}
            {/* Common Properties End*/}

            {/* Text, Textarea, Number Properties Start */}
            {(fieldType === "text" ||
              fieldType === "number" ||
              fieldType === "textarea") && (
              <>
                <label>Placeholder</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Enter a placeholder for the field"
                  value={fieldPlaceholder}
                  onChange={(e) => setFieldPlaceholder(e.target.value)}
                />

                <label>Max Length</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Enter maximum input length this field will accept"
                  value={fieldMaxLength}
                  onChange={(e) => handleLengthChange(e, "max")}
                />
                {fieldMaxLengthError && (
                  <span className="required-mark">{fieldMaxLengthError}</span>
                )}

                <label>Min Length</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Enter maximum input length this field will accept"
                  value={fieldMinLength}
                  onChange={(e) => handleLengthChange(e, "min")}
                />
                {fieldMinLengthError && (
                  <span className="required-mark">{fieldMinLengthError}</span>
                )}
              </>
            )}
            {/* Text, Textarea, Number Properties End */}

            {/* Select options settings for Institute Field */}
            {fieldName === "institute_organization" && (
              <>
                <label>Select Insititute / Organisations for options</label>
                <div className="radio-field institute">
                  <label>
                    <input
                      type="radio"
                      value={"All"}
                      checked={selectedOptionSettings === "All"}
                      onClick={() => {
                        setOptions([]);
                        setExcludedOptions([]);
                        setSelectedOptionSettings("All");
                      }}
                    />
                    All
                  </label>
                  <label>
                    <input
                      type="radio"
                      value={"Eligible Organizations"}
                      checked={
                        selectedOptionSettings === "Eligible Organizations"
                      }
                      onClick={() => {
                        setOptions([""]);
                        setExcludedOptions([]);
                        setSelectedOptionSettings("Eligible Organizations");
                      }}
                    />
                    Eligible Organizations
                  </label>
                  <label>
                    <input
                      type="radio"
                      value={"Non Eligible Organisations"}
                      checked={
                        selectedOptionSettings === "Non Eligible Organisations"
                      }
                      onClick={() => {
                        setOptions([]);
                        setExcludedOptions([""]);
                        setSelectedOptionSettings("Non Eligible Organisations");
                      }}
                    />
                    Non Eligible Organisations
                  </label>
                </div>
              </>
            )}

            {/* Radio , Checkbox, Dropdown Properties Start */}
            {(fieldType === "radio" ||
              fieldType === "checkbox" ||
              (fieldType === "select" &&
                fieldName !== "institute_organization") ||
              (fieldName === "institute_organization" &&
                selectedOptionSettings !== "All")) && (
              <>
                {fieldName === "institute_organization" && (
                  <label>
                    {`Add ${selectedOptionSettings}`}{" "}
                    <Tooltip
                      text={
                        selectedOptionSettings === "Non Eligible Organisations"
                          ? "Added institutes / organizations will be excluded from the list of all and presented to users while registration"
                          : "Only added institutes / organizations will be presented to users while registration"
                      }
                    />
                  </label>
                )}
                {[
                  ...(fieldName === "institute_organization" &&
                  selectedOptionSettings === "Non Eligible Organisations"
                    ? excludedOptions
                    : options),
                ].map((option, index) => {
                  return (
                    <div className={`custom-field-option`} key={index}>
                      {fieldType === "checkbox" ? (
                        <IoIosCheckbox className="custom-field-option-icon" />
                      ) : fieldType === "radio" ? (
                        <IoIosRadioButtonOn className="custom-field-option-icon" />
                      ) : (
                        <IoMdArrowDropdownCircle className="custom-field-option-icon" />
                      )}
                      <input
                        className="form-control"
                        type="text"
                        placeholder={
                          fieldName === "institute_organization"
                            ? `Enter ${selectedOptionSettings}`
                            : `Enter option for the ${fieldType === "select" ? "drop down" : fieldType + " button"}`
                        }
                        value={option}
                        onChange={(e) => handleOptionChange(e, index)}
                        onFocus={(e) => handleOptionChange(e, index)}
                        onBlur={() =>
                          setTimeout(
                            () => setFilteredInstituteSuggestions({}),
                            500
                          )
                        }
                      />
                      {[
                        ...(fieldName === "institute_organization" &&
                        selectedOptionSettings === "Non Eligible Organisations"
                          ? excludedOptions
                          : options),
                      ].length > 1 && (
                        <IoMdTrash
                          className="delete-option-icon"
                          onClick={() => {
                            const array =
                              fieldName === "institute_organization" &&
                              selectedOptionSettings ===
                                "Non Eligible Organisations"
                                ? excludedOptions.concat()
                                : options.concat();

                            array.splice(index, 1);

                            if (
                              fieldName === "institute_organization" &&
                              selectedOptionSettings ===
                                "Non Eligible Organisations"
                            ) {
                              setExcludedOptions(array);
                            } else {
                              setOptions(array);
                            }
                          }}
                        />
                      )}

                      {/* Institute Suggestions */}
                      {filteredInstituteSuggestions.index !== undefined &&
                        filteredInstituteSuggestions.index === index &&
                        filteredInstituteSuggestions.suggestions.length > 0 && (
                          <>
                            <br />
                            <ul className="institute-suggestion-box">
                              {filteredInstituteSuggestions.suggestions.map(
                                (suggestion, i) => (
                                  <li
                                    key={i}
                                    className="institute-suggestion-item"
                                    onClick={() =>
                                      handleInstituteSuggestionClick(
                                        suggestion,
                                        index
                                      )
                                    }
                                  >
                                    {suggestion}
                                  </li>
                                )
                              )}
                            </ul>
                          </>
                        )}
                    </div>
                  );
                })}
                <button
                  className="add-option-btn"
                  onClick={() => {
                    if (
                      [
                        ...(fieldName === "institute_organization" &&
                        selectedOptionSettings === "Non Eligible Organisations"
                          ? excludedOptions
                          : options),
                      ].find((option) => option.trim().length === 0) ===
                      undefined
                    ) {
                      const array = [
                        ...(fieldName === "institute_organization" &&
                        selectedOptionSettings === "Non Eligible Organisations"
                          ? excludedOptions
                          : options),
                      ].concat();
                      array.push("");
                      if (
                        fieldName === "institute_organization" &&
                        selectedOptionSettings === "Non Eligible Organisations"
                      ) {
                        setExcludedOptions(array);
                      } else {
                        setOptions(array);
                      }
                    }
                  }}
                >
                  + Add Option
                </button>
              </>
            )}
            {/* Radio, Checkbox, Dropdown Properties End */}

            {/* File Upload Properties Start */}
            {fieldType === "file" && (
              <>
                <label>
                  Max. file size allowed for upload (In MB) ?
                  <span className="required-mark"> *</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  value={fieldMaxFileSize}
                  onChange={handleMaxFileSizeChange}
                />
                {fieldMaxFileSizeError && (
                  <span className="required-mark">{fieldMaxFileSizeError}</span>
                )}
              </>
            )}
            {/* File Upload Properties End */}

            {/* Accept Box Properties Start */}
            {fieldType === "checkbox-text" && (
              <>
                <label>
                  Check Box Text
                  <span className="required-mark"> *</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Enter the text to show with the check box"
                  value={acceptText}
                  onChange={(e) => setAcceptText(e.target.value)}
                />
              </>
            )}
            {/* Accept Box Properties End */}
            <br />
            {saveError && <span className="required-mark">{saveError}</span>}
          </div>
          <div className="custom-field-modal-footer">
            <button
              type="button"
              className="add-field-button btn btn-primary"
              onClick={handleSaveField}
            >
              Save
            </button>
            <button
              type="button"
              className="close-modal-btn"
              onClick={() => {
                resetAllFields();
                setFieldType("");
                onClose();
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>,
      document.body
    );
  }
};

export default CustomFieldModal;
