import { Button, Form, Switch } from "antd";
import React, { useEffect, useState } from "react";
import CKEditor from "react-ckeditor-component";
import "./submissionSettings.css";
import SubmissionSettingsCaseStudy from "./SubmissionSettingsCaseStudy";

const SubmissionSettings = (props) => {
  const [caseStudyForm] = Form.useForm();
  const [editorContent, setEditorContent] = useState(""); // State for CKEditor content
  const [uploading, setUploading] = useState(false);
  const [caseStudyData, setCaseStudyData] = useState([]);

  useEffect(() => {
    if (props.submissionSettings) {
      caseStudyForm.setFieldsValue({
        showCaseStudy: props.submissionSettings.showCaseStudy,
      });
      setCaseStudyData(props.submissionSettings.caseStudyData);
      setEditorContent(props.submissionSettings.guidelines);
    } else {
      setEditorContent("");
      setCaseStudyData([]);
      caseStudyForm.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.submissionSettings]);

  const onFinish = async (values) => {
    try {
      setUploading(true);

      const formData = {
        ...values,
        guidelines: editorContent,
        caseStudyData,
      };

      // Log the JSON output
      console.log("Form JSON Output:", JSON.stringify(formData, null, 2));
      props.onSubSettingSave(JSON.stringify(formData));
    } catch (error) {
      console.error("Upload failed:", error);
    } finally {
      setUploading(false);
    }
  };

  const handleCaseStudyDataChange = (csData) => {
    const updatedCaseStudyData = [...caseStudyData];
    updatedCaseStudyData[csData.index] = csData.data;
    setCaseStudyData(updatedCaseStudyData);
  };

  return (
    <Form
      name="caseStudyForm"
      form={caseStudyForm}
      onFinish={onFinish}
      layout="vertical"
    >
      {/* Switch Toggle */}
      <Form.Item
        label="Show Case Study for Round"
        name="showCaseStudy"
        valuePropName="checked"
        initialValue={false}
      >
        <Switch />
      </Form.Item>

      {/* CKEditor for Guidelines */}
      <Form.Item label="Submission Guidelines" name="guidelines">
        <CKEditor
          activeClass="p10"
          content={editorContent}
          events={{
            change: (evt) => setEditorContent(evt.editor.getData()),
          }}
        />
      </Form.Item>

      {/* Case Study Upload Form */}
      {caseStudyData.length > 0 &&
        caseStudyData.map((cs, index) => {
          return (
            <SubmissionSettingsCaseStudy
              key={index}
              caseStudy={cs}
              index={index}
              onCaseStudyDataChange={handleCaseStudyDataChange}
              onDeleteCaseStudy={(index) => {
                const updatedCaseStudyData = [...caseStudyData];
                updatedCaseStudyData.splice(index, 1);
                setCaseStudyData(updatedCaseStudyData);
              }}
            />
          );
        })}

      {caseStudyData.length === 0 && (
        <SubmissionSettingsCaseStudy
          caseStudy={null}
          index={0}
          onCaseStudyDataChange={handleCaseStudyDataChange}
        />
      )}

      {/* Add Case Study Button */}
      <Form.Item>
        <Button
          type="primary"
          onClick={() => {
            setCaseStudyData([...caseStudyData, {}]);
          }}
        >
          Add Case Study
        </Button>
      </Form.Item>

      {/* Submit Button */}
      <Form.Item>
        <Button
          type="danger"
          size="large"
          htmlType="submit"
          loading={uploading}
        >
          <b>Submit</b>
        </Button>
      </Form.Item>
    </Form>
  );
};

export default SubmissionSettings;
