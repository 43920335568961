import React, { useState, useEffect } from "react";
import LeftSideBar from "../LeftSideBar/LeftSideBar";
import { Table, Input } from "antd";
import { useParams } from "react-router-dom";
import "./round.css";
import { Col, Row, Container } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import config from "../../CommonFiles/config.json";
import { Drawer } from "antd";
import SubmissionSettings from "../../User/Forms/submissionSettings";
const mainurl = Object.values(config["URL"]);
const APIBASEURL = Object.values(config["API_BASEPATH"]).join("");
const URL = mainurl.join("");
const mainURL = URL;

export default function Round(props) {
  const { id } = useParams();
  const [activeToptab, setActiveToptab] = useState(3);
  const [currentPage, setCurrentPage] = useState(1);
  const [registerList, setRegisterList] = useState([]);
  const [allRounds, setAllRounds] = useState([]);
  const [roundDetail, setRoundDetail] = useState([]);
  const [registerCount, setTotalregisterCount] = useState();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedroundid, setSelectedroundid] = useState(props.match.params.id);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      // pageSize:10,
      total: 0,
    },
  });

  const [open, setOpen] = useState(false);

  const onClose = () => {
    setOpen(false);
  };

  const openSubmissionSettings = () => {
    setOpen(true);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const USER_TOKEN = localStorage.getItem("token");
  console.log("user token", USER_TOKEN);
  const AuthStr = "Bearer ".concat(USER_TOKEN);
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  useEffect(() => {
    // const tokencheck = localStorage.getItem("token")
    console.log("tableParams", id);

    if (USER_TOKEN === null) {
      props.history.push(`/login`);
    } else {
      callApi(activeToptab, currentPage);
    }
    roundDetailApi();
  }, [id]);

  const roundDetailApi = () => {
    axios({
      method: "post",
      url: mainURL + "/opportunity/round",
      data: { round_id: props.match.params.id },
      headers: { Authorization: AuthStr },
    })
      .then((response) => {
        const alldata = response.data.data;
        setRoundDetail(alldata);

        console.log("Error alldata.quiz_basic", alldata);
      })
      .catch(function (response) {
        //handle error
        console.log("Error while calling api", response);
      });
  };

  useEffect(() => {
    setRoundDetail(roundDetail);
  }, [roundDetail]);

  const callApi = (activeToptab, currentPage) => {
    console.log("tableParams", tableParams.pagination);
    let fullURL;
    // if (activeToptab === 3) {
    fullURL =
      mainURL +
      "/profile/dashboard/registered/opportunities/" +
      localStorage.getItem("hostingId") +
      `?page=${currentPage}&limit=10&round_id=${props.match.params.id}&round_status=${activeToptab}`;
    // } else {
    //   fullURL =
    //     mainURL +
    //     "/profile/dashboard/registered/opportunities/" +
    //     localStorage.getItem("hostingId") +
    //     `?page=${currentPage}&limit=10&round_status=${activeToptab}`;
    // }
    axios({
      method: "get",
      url: fullURL,
      headers: { Authorization: AuthStr },
    })
      .then((response) => {
        // console.log("response", response)
        // const alldatas = response.data.data.rows;
        setTotalregisterCount(response.data.registerCount);
        let finalData = response.data.data;
        console.log(
          "data get",
          finalData.filter((list) => list.round_status === activeToptab)
        );
        setRegisterList(
          finalData.filter((list) => list.round_status === activeToptab)
        );
        console.info("alldata", response.data.data);
        setTableParams({
          pagination: {
            current: currentPage,
            pageSize: 10,
            total: response.data.registerCount,
          },
        });
        // console.info("alldata", opportunity);
      })
      .catch(function (response) {
        //handle error
        console.log("Error while calling api", response);
      });
  };

  const data = [];
  if (registerList.length > 0) {
    for (let i = 0; i < registerList.length; i++) {
      data.push({
        key: registerList[i].id,
        ids: <span>{i < 9 ? "0" + (i + 1) : i + 1}</span>,
        name: (
          <div className="d-flex">
            <div className="imgeshow w-40 mr-3">
              <img
                className="list-icon-img"
                src={require("../../../images/total_reg.png").default}
                alt="od-banner"
              />
            </div>
            <div className="teamname pt-1">
              {registerList[i].name}{" "}
              <span>{registerList[i].teamCount} Players</span>
              <br />
              <div className="">{registerList[i].school}</div>
            </div>
          </div>
        ),
        status: (
          <p className="align-center">
            {activeToptab === 1 ? (
              <span className="incomplete">Submitted</span>
            ) : (
              "Not Submitted"
            )}
          </p>
        ),
        score: (
          <div>
            <Input
              defaultValue={registerList[i].scoreData || ""}
              onChange={(e) =>
                ScoreEnter(e.target.value, registerList[i].id, "age")
              }
            />
          </div>
        ),
        action: (
          <div className="d-flex roundStatus">
            {activeToptab != 1 && (
              <span
                onClick={() => singleroundStatus(registerList[i].id, 1)}
                className="width-4"
              >
                <img
                  src={require("../../../images/Icon-Approve.png").default}
                  alt=""
                  srcset=""
                />
              </span>
            )}
            {(activeToptab === 1 ||
              activeToptab === 2 ||
              activeToptab === 3) && (
              <span>
                <img
                  src={require("../../../images/Icon-Reject.png").default}
                  alt=""
                  srcset=""
                  onClick={() => singleroundStatus(registerList[i].id, 0)}
                />
              </span>
            )}
            <span onClick={() => singleroundStatus(registerList[i].id, 2)}>
              <img
                src={require("../../../images/Icon-Star.png").default}
                alt=""
                srcset=""
                className={registerList[i].round_status != 2 && "grayscale"}
              />
            </span>
            {registerList[i].submissionfile != null && (
              <span>
                <a href={registerList[i].submissionfile} target="__blank">
                  <img
                    src={require("../../../images/Icon-Download.png").default}
                    alt=""
                    srcset=""
                  />
                </a>
              </span>
            )}
          </div>
        ),
      });
    }
  }

  const statusClick = (roundid) => {
    Swal.fire({
      title: "Do you want to Approve",
      // text: "You Want Start the quiz !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        console.log(localStorage.getItem("userQA"));
        const USER_TOKEN = localStorage.getItem("token");
        console.log("user token", USER_TOKEN);
        const AuthStr = "Bearer ".concat(USER_TOKEN);
        axios({
          method: "post",
          url: mainURL + "/profile/rounds/registered/opportunities/" + roundid,
          data: { current_round: 1 },
          headers: { Authorization: AuthStr },
        })
          .then((response) => {
            // setUploadmsg("Submission has been successfully uploaded");
          })
          .catch(function (response) {
            //handle error
            console.log("Error while calling api", response);
          });
        // setEndQuiz(true)
        // props.history.push(`/quiz-detail`)
      }
    });
  };

  const ScoreEnter = (score, reg_id) => {
    const USER_TOKEN = localStorage.getItem("token");
    console.log("user reg id", reg_id);
    const AuthStr = "Bearer ".concat(USER_TOKEN);
    axios({
      method: "post",
      url: mainURL + "/profile/rounds/registered/opportunities/" + reg_id,
      data: {
        current_round: 1,
        round_id: parseInt(props.match.params.id),
        opportunity_id: parseInt(localStorage.getItem("hostingId")),
        score: score,
      },
      headers: { Authorization: AuthStr },
    })
      .then((response) => {
        console.log("jhghjghg", response);
        callApi(activeToptab, currentPage);
        setSelectedRowKeys([]);
      })
      .catch((err) => {
        console.log("jhghjghg", err);
      });
  };

  const columns = [
    {
      title: "Participants & College",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.length - b.name.length,
    },
    // {
    //   title: "Round Panel",
    //   dataIndex: "round",
    //   key: "round",
    //   filters: [
    //     {
    //       text: "London",
    //       value: "London",
    //     },
    //     {
    //       text: "New York",
    //       value: "New York",
    //     },
    //   ],
    //   onFilter: (value, record) => record.round.indexOf(value) === 0,
    // },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      filters: [
        {
          text: "Completed",
          value: 1,
        },
        {
          text: "Incomplete",
          value: 0,
        },
      ],
      onFilter: (value, record) => record.status === value,
      render: (_, record) => (
        <>
          {record.status === 0 ? (
            <p className="incomplete">Incomplete</p>
          ) : (
            <p className="complete">Complete</p>
          )}
        </>
      ),
    },
    {
      title: "Score",
      dataIndex: "score",
      key: "score",
      width: 120,
      sorter: (a, b) => a.score.length - b.score.length,
    },
    {
      title: "ACTION",
      dataIndex: "action",
      key: "action",
    },
  ];
  const topheader = [
    {
      name: "In Progress",
      id: 3,
    },
    {
      name: "Shortlisted",
      id: 1,
    },
    {
      name: "Mark as Review",
      id: 2,
    },
    {
      name: "Rejected",
      id: 0,
    },
  ];
  function changeTab(tab) {
    setActiveToptab(tab);
    setTotalregisterCount(0);
    setCurrentPage(1);
    callApi(tab, 1);
  }

  const roundStatus = (status) => {
    axios({
      method: "post",
      url: mainURL + "/profile/rounds/registered/opportunities",
      data: {
        current_round: 1,
        round_status: status,
        register_ids: selectedRowKeys,
      },
    })
      .then((response) => {
        console.log("jhghjghg", response);
        callApi(activeToptab, 1);
        setSelectedRowKeys([]);
      })
      .catch((err) => {
        console.log("jhghjghg", err);
      });
  };
  const singleroundStatus = (id, status) => {
    const USER_TOKEN = localStorage.getItem("token");
    console.log("user token", USER_TOKEN);
    const AuthStr = "Bearer ".concat(USER_TOKEN);
    axios({
      method: "post",
      url: mainURL + "/profile/rounds/registered/opportunities/" + id,
      data: {
        current_round: 1,
        round_status: status,
        round_id: parseInt(props.match.params.id),
      },
      headers: { Authorization: AuthStr },
    })
      .then((response) => {
        console.log("jhghjghg", response);
        callApi(activeToptab, currentPage);
        setSelectedRowKeys([]);
      })
      .catch((err) => {
        console.log("jhghjghg", err);
      });
  };

  const pageChnage = (e) => {
    console.log(e);
    setCurrentPage(e.current);
    callApi(activeToptab, e.current);
  };

  const uploadCasestudy = async () => {
    const { value: file } = await Swal.fire({
      title: "Select File",
      input: "file",
      inputAttributes: {
        accept: "application/pdf",
        "aria-label": "Upload your Resul",
      },
    });
    const formData = new FormData();

    let apidata;

    formData.append("attachments", file, parseInt(props.match.params.id));
    formData.append("round_id", parseInt(props.match.params.id));
    const USER_TOKEN = localStorage.getItem("token");
    console.log("user token", USER_TOKEN);
    const AuthStr = "Bearer ".concat(USER_TOKEN);
    axios({
      method: "post",
      url:
        mainURL +
        "/opportunity/images/" +
        parseInt(localStorage.getItem("hostingId")),
      data: formData,
      headers: { Authorization: AuthStr },
    })
      .then((response) => {})
      .catch(function (error) {
        //handle error

        console.log("Error while calling api", error.response.data.auth);
      });

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        Swal.fire({
          title: "Case Study has been uploaded",
          imageAlt: "The uploaded picture",
        });
      };
      roundDetailApi();

      reader.readAsDataURL(file);
    }
  };
  const uploadresult = async () => {
    const { value: file } = await Swal.fire({
      title: "Select File",
      input: "file",
      inputAttributes: {
        accept: "*",
        "aria-label": "Upload your Result",
      },
    });
    const formData = new FormData();

    let apidata;

    formData.append("result", file, parseInt(props.match.params.id));
    formData.append("round_id", parseInt(props.match.params.id));
    const USER_TOKEN = localStorage.getItem("token");
    console.log("user token", USER_TOKEN);
    const AuthStr = "Bearer ".concat(USER_TOKEN);
    axios({
      method: "post",
      url: mainURL + "/opportunity/result/",
      data: formData,
      headers: { Authorization: AuthStr },
    })
      .then((response) => {})
      .catch(function (error) {
        //handle error

        console.log("Error while calling api", error.response.data.auth);
      });

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        Swal.fire({
          title: "Result file has been uploaded",
          imageAlt: "The uploaded picture",
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const uploadShortlistList = async () => {
    const { value: file } = await Swal.fire({
      title: "Upload Shortlisted Candidates",
      input: "file",
      html: `
        <div class="text-center">
          <a class="btn btn-info" href="https://gradimages.s3.ap-south-1.amazonaws.com/templates/ShortlistEmailTemplate.xlsx" download>Download Template</a>
        </div>
      `,
      inputAttributes: {
        accept: ".xlsx, .xls, .csv",
        "aria-label": "Upload shortlisted candidates email list",
      },
      showCancelButton: true,
      confirmButtonText: "Upload List",
      cancelButtonText: "Cancel",
    });

    if (file) {
      const formData = new FormData();
      formData.append("excelfile", file);
      const USER_TOKEN = localStorage.getItem("token");
      const AuthStr = "Bearer ".concat(USER_TOKEN);
      axios({
        method: "post",
        url: mainURL + "/" + localStorage.getItem("hostingId") + "/shortlist/" + props.match.params.id,
        data: formData,
        headers: { Authorization: AuthStr },
      })
        .then((response) => {
          Swal.fire({
            title: "File Uploaded Successfully",
            text: `Shortlisted ${response.data.emails.length} email ids.`,
            icon: "success",
          });
        })
        .catch(function (error) {
          //handle error
          Swal.fire({
            title: "Error in processing file",
            text: `${error}`,
            icon: "error",
          });
        });
    }
  };

  const deleteResult = () => {
    axios({
      method: "post",
      url: mainURL + "/opportunity/round",
      data: { deletefile: 1, round_id: parseInt(props.match.params.id) },
      headers: { Authorization: AuthStr },
    })
      .then((response) => {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "File has been deleted",
          showConfirmButton: false,
          timer: 1500,
        });
        window.location.reload();
      })
      .catch(function (response) {
        //handle error
        console.log("Error while calling api", response);
      });
  };

  async function DonwloadResult(id) {
    console.log("roundDetail", roundDetail);
    let finalurl;
    if (roundDetail.type === "quiz") {
      finalurl =
        mainURL +
        "/quiz/result/excel?opportunity_id=" +
        localStorage.getItem("hostingId") +
        "&round_id=" +
        props.match.params.id;
    } else {
      finalurl =
        mainURL +
        "/submissions/result/excel?opportunity_id=" +
        localStorage.getItem("hostingId") +
        "&round_id=" +
        props.match.params.id;
    }
    try {
      const result = await axios({
        method: "get",
        url: finalurl,
      });
      console.log("product re", result.data);
      let excelurl = APIBASEURL + "/" + result.data.path;
      window.open(excelurl, "_blank");
    } catch (error) {
      console.log(error);
    }
    if (roundDetail.type === "quiz") {
      try {
        const result = await axios({
          method: "get",
          url:
            mainURL +
            "/quiz/score/excel?opportunity_id=" +
            localStorage.getItem("hostingId") +
            "&round_id=" +
            props.match.params.id,
        });
        console.log("product re", result.data);
        let excelurl = APIBASEURL + "/" + result.data.path;
        window.open(excelurl, "_blank");
      } catch (error) {
        console.log(error);
      }
    }
  }

  const deleteCaseStudy = (roundDetail) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        axios({
          method: "delete",
          url:
            mainURL +
            `/opportunity/${roundDetail.opportunity_id}/round/${roundDetail.id}/casestudy`,
          headers: { Authorization: AuthStr },
        })
          .then((response) => {
            if (response.data.status === 1) {
              Swal.fire(
                "Deleted!",
                "The file has been deleted.",
                "success"
              ).then(function () {
                window.location.reload();
              });
            } else {
              Swal.fire(
                "Error",
                "Something went wrong. Please try again.",
                "error"
              );
            }
          })
          .catch(function (error) {
            if (error) {
              //handle error
              Swal.fire(
                "Error",
                "Something went wrong. Please try again.",
                "error"
              );
              console.log("Error while calling api", error);
            }
          });
      }
    });
  };

  const onAllRoundsDataLoaded = (data) => {
    setAllRounds(data);
  };

  const onSubSettingSave = (subSettings) => {
    if (
      allRounds.length > 0 &&
      allRounds.find((round) => round.id === roundDetail.id)
    ) {
      const updatedRounds = allRounds.map((round) => {
        if (round.id === roundDetail.id) {
          return { ...round, submission_settings: subSettings };
        }
        return round;
      });

      axios({
        method: "post",
        url: mainURL + "/opportunity/round",
        data: {
          rounds: updatedRounds,
          opportunity_id: roundDetail.opportunity_id,
        },
        headers: { Authorization: AuthStr },
      })
        .then((response) => {
          if (response.data.status === 1) {
            setRoundDetail({
              ...roundDetail,
              submission_settings: subSettings,
            });
            setAllRounds(updatedRounds);
            setOpen(false);
            Swal.fire(
              "Success",
              "Submission settings updated successfully.",
              "success"
            );
          }
        })
        .catch(function (response) {
          //handle error
          console.log("Error while calling api", response);
          Swal.fire(
            "Error",
            "Something went wrong. Please try again.",
            "error"
          );
        });
    }
  };

  return (
    <div className="wrapper pt-5">
      {/* START LEFTSIDEBAR */}
      <LeftSideBar onAllRoundsDataLoaded={onAllRoundsDataLoaded} />
      {/* END LEFTSIDEBAR */}
      {/* START PAGE CONTENT START */}
      <div className="contents opportunityDashboard">
        <div className="page_content">
          <Container>
            <Row className="pt-5 card_box_sec">
              <Col className="col-md-4">
                <h5 className="pb-3 rounddetail-topheading">
                  {roundDetail.title}
                </h5>
              </Col>
              <Col className="col-md-8 text-md-right">
                <div className="d-flex roundAction">
                  <button
                    className="mr-3"
                    onClick={() => roundStatus(1)}
                    disabled={selectedRowKeys.length === 0 && "disable"}
                  >
                    <img
                      src={require("../../../images/Icon-Approve.png").default}
                      alt=""
                      srcset=""
                    />{" "}
                    Shortlist
                  </button>
                  <button
                    className="mr-3"
                    onClick={() => roundStatus(0)}
                    disabled={selectedRowKeys.length === 0 && "disable"}
                  >
                    <img
                      src={require("../../../images/Icon-Reject.png").default}
                      alt=""
                      srcset=""
                    />{" "}
                    Reject
                  </button>
                  <button
                    className="mr-3"
                    onClick={() => roundStatus(2)}
                    disabled={selectedRowKeys.length === 0 && "disable"}
                  >
                    <img
                      src={require("../../../images/Icon-Star.png").default}
                      alt=""
                      srcset=""
                    />{" "}
                    Marked for Review
                  </button>
                  <button onClick={() => DonwloadResult()}>
                    <img
                      src={require("../../../images/Icon-Download.png").default}
                      alt=""
                      srcset=""
                    />{" "}
                    Evaluation File
                  </button>
                  {/* <span className="dotcss ">...</span>
                  <span onClick={()=>DonwloadResult()}> Download result</span> */}
                </div>
              </Col>
            </Row>
          </Container>
          <div className="topboxflex">
            {topheader.map((item) => (
              <span
                className={
                  item.id === activeToptab ? "pr-3 active " : "pr-3 tabcount"
                }
                onClick={() => changeTab(item.id)}
              >
                {item.name} <span>({registerList.length})</span>
              </span>
            ))}
            <div className="right-side-btn">
              {roundDetail.length}
              {roundDetail.resultfile && (
                <button
                  className="btn menubtn resultuploadbtn"
                  onClick={() => deleteResult()}
                >
                  {" "}
                  <i className="fa fa-trash"></i> Result
                </button>
              )}
              <button
                className="btn menubtn resultuploadbtn"
                onClick={() => uploadresult()}
              >
                {" "}
                <i className="fa fa-upload"></i> &nbsp; Result{" "}
              </button>

              {roundDetail.type === "submission" && (
                <>
                  <button
                    className="btn menubtn resultuploadbtn"
                    onClick={() => setOpen(true)}
                  >
                    {" "}
                    <i className="fa fa-cog"></i> &nbsp; Submission Settings{" "}
                  </button>
                </>
              )}

              {roundDetail.is_eleminator == "yes" && (
                <button
                  className="btn menubtn resultuploadbtn"
                  onClick={() => uploadShortlistList()}
                >
                  {" "}
                  <i className="fa fa-upload"></i> &nbsp; Shortlist Via CSV{" "}
                </button>
              )}
            </div>
          </div>
          <div>
            <Table
              bordered
              rowSelection={rowSelection}
              dataSource={data}
              columns={columns}
              pagination={tableParams.pagination}
              onChange={(e) => pageChnage(e)}
            />
            ;
          </div>
        </div>
      </div>
      <Drawer
        className="round-settings"
        title={`Submission Settings`}
        placement="right"
        onClose={onClose}
        open={open}
        size="large"
      >
        <SubmissionSettings
          onSubSettingSave={onSubSettingSave}
          submissionSettings={
            roundDetail.submission_settings !== undefined
              ? JSON.parse(roundDetail.submission_settings)
              : null
          }
        />
      </Drawer>
    </div>
  );
}
